import axios from "axios"
import siteConfig from '@iso/config/site.config';
import authHeader from "./AuthTokenHeader";
import { createBrowserHistory } from 'history';
import { notification } from 'antd';

const API_URL = siteConfig.apiUrl;

const history = createBrowserHistory();

const axiosApi = axios.create({
    baseURL: API_URL,
});

axiosApi.defaults.headers["Access-Control-Allow-Origin"] = "*";

axiosApi.interceptors.request.use(
    async config =>
    {
        if(authHeader()){
            config.headers.Authorization =  await authHeader();
            config.headers["Access-Control-Allow-Origin"] = "*";
        }else{
            config.headers["Access-Control-Allow-Origin"] = "*";
        }
        return config;
    },
    error => Promise.reject(error)
);

axiosApi.interceptors.response.use(
    response => {
        if (response.status >= 200 || response.status <= 299)
            return response;
        throw response;
    },
    error => {
        if (!error.response){
            return new Promise((resolve, reject) => {
                reject(error);
            })
        }
        let message;
        if (error.response && error.response.status) {
            if (error.response.status === 401){
               localStorage.removeItem("id_token");
                history.go(0)
                //to implement refresh token
            }
            if (error.response.status === 403){
                notification.error({
                    message: "You dont have a active subscription",
                });
                history.push('/without-permission')
                window.location.reload();
                //to implement refresh token
            }
            switch (error.response.status) {
                case 404:
                    message = "Sorry! the page you are looking for could not be found";
                    break;
                case 500:
                    message = (JSON.parse(error.response.request.response).message) ? JSON.parse(error.response.request.response).message : " Sorry! something went wrong, please contact our support team at sales@beckerdata.com";
                    notification.error({
                        message: `Request Error: ${error.response.config.url}`,
                        description: message,
                    });
                    break;
                default:
                    message = error.response.data;
                    break;
            }
        }
        throw message
    }
);


export async function downloadFile(url, config, filename) {
    return axiosApi
        .get(url, {
            ...config,
            responseType: 'blob',
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
}


export async function get(url, config = {}) {
    return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
    return axiosApi
        .post(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function postUpload(url, data, config) {
    return axiosApi
        .post(url, data, {
            ...config,
            headers: { "content-type": "multipart/form-data" },
        })
        .then((response) => response.data);
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, { ...data }, { ...config })
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, { ...config })
        .then(response => response.data)
}

export async function download(url){
    return await axiosApi.get(url, {
        method: 'GET',
        responseType: 'blob', // important
    }).then(response => response.data)
}
