export const GET_INTERMEDIATES = "GET_INTERMEDIATES";
export const GET_INTERMEDIATES_SUCCESS = "GET_INTERMEDIATES_SUCCESS";
export const GET_INTERMEDIATES_ERROR = "GET_INTERMEDIATES_ERROR";

export const EXPORT_INTERMEDIATES = "EXPORT_INTERMEDIATES";
export const EXPORT_INTERMEDIATES_SUCCESS = "EXPORT_INTERMEDIATES_SUCCESS";
export const EXPORT_INTERMEDIATES_ERROR = "EXPORT_INTERMEDIATES_ERROR";

export const SET_INTERMEDIATES_QUERY = "SET_INTERMEDIATES_QUERY";

export const SET_INTERMEDIATES_PAGINATION = "SET_INTERMEDIATES_PAGINATION";
export const CLEAR_INTERMEDIATES_STATE = "CLEAR_INTERMEDIATES_STATE";
