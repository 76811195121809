import React, {useEffect, useState} from "react";
import {Alert, Col, Row, Typography, Form, Spin, Button, Divider} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {closeModalMain, openModalMain} from "../../../../redux/rootModal/actions";
import {clearIntermediateState, exportIntermediate} from "../../../../redux/user/intermediates/actions";
import {clearActiveIngredientState} from "../../../../redux/user/activeIngredients/actions";


const IntermediatesViewModal = ({intermediates}) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const {Text} = Typography;
    const {purchase_data} = useSelector(state => state.Auth)
    const {exportLoading, success} = useSelector(state => state.Intermediates)
    const [showSuccessButton, setShowSuccessButton] = useState(false)
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);

    const message = (
        <div style={{fontSize: "16px"}}>
            <Text strong>You have {purchase_data.searched_remaining} searches remaining</Text>.<br/>{" "}
            {purchase_data.searched_remaining === 0 ?
                "Please purchase more searches to continue." :
                ""}
        </div>
    );

    useEffect(() => {
        setShowSuccessButton(false)
    }, [modalVisibility])

    const handleAdditionalSearchForm = () => {
        dispatch(closeModalMain());
        let props = {
            modalVisibility: true,
            modalType: "ADDITIONAL_SEARCH_MODAL",
            modalProps: {
                title: "Buy Additional Searches",
                width: 600,
                maskClosable: false,
                style: { top: "30px" },
                footer: [
                    <Button onClick={() => {dispatch(closeModalMain())}}>Cancel</Button>,
                    <Button form="additional_search_form" key="submit" htmlType="submit" type="primary">Proceed To Checkout</Button>
                ],
            },
        };
        dispatch(openModalMain(props));
    }

    const onInterExport = () => {
        dispatch(exportIntermediate(intermediates.id, intermediates.name))
    }


    if (success !== '') {
        setShowSuccessButton(true)
        dispatch(clearIntermediateState())
    }
    return (
        <Spin spinning={exportLoading}>
            <Form
                id="inter_export_form"
                name="inter_export_form"
                form={form}
                onFinish={onInterExport}
            >
                <div style={{fontSize: "15px"}}>
                    <Row style={{marginBottom: "5px"}}>
                        <Col span={6}>
                            <Text strong>Name: </Text>
                        </Col>
                        <Col>
                            <Text>{intermediates.name}</Text>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "24px"}}>
                        <Col span={6}>
                            <Text strong>CAS: </Text>
                        </Col>
                        <Col>
                            <Text>{intermediates.cas_registry_number}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Alert
                            style={{width: "100%"}}
                            message={message}
                            description={`${(purchase_data.searched_remaining === 0) ? 
                                '' : 
                                'You are about to use one of your searches. Do you want to proceed?'}`}
                            type={`${(purchase_data.searched_remaining === 0) ? 'error' : 'info'}`}
                        />
                    </Row>
                    {(purchase_data.searched_remaining === 0) ?
                        '' :
                    <Row style={{marginTop: "5px"}}>
                        <Alert
                            style={{width: "100%",paddingTop:"5px",paddingBottom:"5px"}}
                            description={`${(purchase_data.searched_remaining === 0) ?
                                '' :
                                'The intermediate PDF can take anywhere from 1-5 minutes. Please be patient.'}`}
                            type={'warning'}
                        />
                    </Row>}
                    <Divider style={{margin: "18px 0"}}/>
                    <Row>
                        <Col span={12}>
                            <Button loading={exportLoading} onClick={() => dispatch(closeModalMain())}>Cancel</Button>
                        </Col>
                        <Col span={12} style={{display: "flex", justifyContent: "end"}}>
                            {(purchase_data.searched_remaining !== 0) ?
                            (showSuccessButton) ?
                                <Button className={'success-button'}>PDF Downloaded Successfully</Button> :
                                <Button form="inter_export_form" key="submit" htmlType="submit" type="primary"
                                        loading={exportLoading}>Proceed & Download PDF</Button> :
                                <Button type="primary" onClick={handleAdditionalSearchForm}>Buy additional searches</Button>}
                        </Col>
                    </Row>
                </div>
            </Form>
        </Spin>
    )


}

export default IntermediatesViewModal
