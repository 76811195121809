import React from "react";


const NoRemainingSearchModal = () => {
    return (
        <h3> Would you like to purchase searches now?</h3>
    )
}

export default NoRemainingSearchModal
