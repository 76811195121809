export const GET_ACTIVE_INGREDIENTS = "GET_ACTIVE_INGREDIENTS";
export const GET_ACTIVE_INGREDIENTS_SUCCESS = "GET_ACTIVE_INGREDIENTS_SUCCESS";
export const GET_ACTIVE_INGREDIENTS_ERROR = "GET_ACTIVE_INGREDIENTS_ERROR";

export const EXPORT_ACTIVE_INGREDIENTS = "EXPORT_ACTIVE_INGREDIENTS";
export const EXPORT_ACTIVE_INGREDIENTS_SUCCESS = "EXPORT_ACTIVE_INGREDIENTS_SUCCESS";
export const EXPORT_ACTIVE_INGREDIENTS_ERROR = "EXPORT_ACTIVE_INGREDIENTS_ERROR";

export const SET_ACTIVE_INGREDIENTS_QUERY = "SET_ACTIVE_INGREDIENTS_QUERY";

export const SET_ACTIVE_INGREDIENTS_PAGINATION = "SET_ACTIVE_INGREDIENTS_PAGINATION";

export const CLEAR_ACTIVE_INGREDIENTS_STATE = "CLEAR_ACTIVE_INGREDIENTS_STATE";
