import {IMPORT_INTERMEDIATE_SUCCESS} from "../../redux/admin/Settings/actionTypes";

export const POST_LOGIN = "/login";
export const POST_REGISTER = "/register";
export const GET_USER_STATE = "/user-state";
export const VERIFY_EMAIL_URL = "/verify-email";
export const RESEND_VERIFICATION_EMAIL = "/email/verification-notification";
export const PASSWORD_CHANGE = "/change-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_RESET = "/reset-password";
export const GET_MENU = "/menu";
export const GET_ROUTES = "/routes";
export const PROFILE = "/profile";
export const USERS = "/users";
export const PURCHASES = "/purchases";

export const PACKAGES = "/packages";

export const IMPORT_AI = "/import/active-ingredient";
export const IMPORT_INTERMEDIATE = "/import/intermediate";
export const IMPORT_SETTINGS = "/import/last-import";
export const SETUP_MAX_NUMBER = "/setup-ai-max-number";

//Logs
export const AI_SEARCH_LOGS = "/ai-search-logs";
export const AI_VIEWED_LOGS = "/ai-view-logs";

export const INTER_SEARCH_LOGS = "/inter-search-logs";
export const INTER_VIEWED_LOGS = "/inter-view-logs";

//User
export const USER_PURCHASES = "/user/purchases";
export const USER_PACKAGES = "/user/packages";


//
export const ACTIVE_INGREDIENTS = "/user/active-ingredients";
export const INTERMEDIATES = "/user/intermediates";

//Stripe
export const STRIPE_CHECKOUT = "/user/create-checkout-session";

