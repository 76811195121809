export const parseError = (error) => {
    var message = "";
    const errorobject = error.errors;

    Object.keys(errorobject).map(function(key, index) {
        if (errorobject[key]) {
            errorobject[key].map((msg) => {
                message = message + msg + '\n'
            })
        }
    });
    return message;
};

export const setFormErrors = (errorObject) => {
    // Transform the errors into the format expected by Ant Design
    const formErrors = Object.keys(errorObject).map(key => ({
        name: key,
        errors: errorObject[key],
    }));
    return formErrors
}

export const validateFromServer = errors => {
    const fieldsError = [];
    errors.errors.map(error => {
        fieldsError.push({
            name: error.field,
            errors: [error.message],
        })
    })
    return fieldsError;
}
