import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import {EXPORT_ACTIVE_INGREDIENTS, GET_ACTIVE_INGREDIENTS} from "./actionTypes";
import {
    getActiveIngredientsSuccess,
    getActiveIngredientsError,
    exportActiveIngredientsSuccess,
    exportActiveIngredientsError,
    setActiveIngredientPagination
} from "./actions";

import {getActiveIngredientsRequest, exportActiveIngredientsRequest} from '../../../library/http/backendHelpers';
import moment from "moment/moment";
import {setAuthState} from "../../auth/actions";

function* getActiveIngredientsSaga({payload: {filters}}){
    try {
        const response = yield call(getActiveIngredientsRequest, filters);
        yield put(setActiveIngredientPagination(response.meta))
        yield put(getActiveIngredientsSuccess(response.data))
      } catch (error) {
        yield put(getActiveIngredientsError(error));
      }
}

function* exportActiveIngredientsSaga({payload: {id, name}}){
    try {
        const filename  = 'active-ingredient-'+name+'-'+ moment().format('DDMMMYYYY') + '.pdf';
        const response = yield call(exportActiveIngredientsRequest, id, filename);
        yield put(setAuthState());
        yield put(exportActiveIngredientsSuccess())
    } catch (error) {
        yield put(exportActiveIngredientsError(error));
    }
}

function* activeIngredientsSaga() {
    yield takeEvery(GET_ACTIVE_INGREDIENTS, getActiveIngredientsSaga);
    yield takeEvery(EXPORT_ACTIVE_INGREDIENTS, exportActiveIngredientsSaga);
}

export default activeIngredientsSaga
