import {
    START_STRIPE_CHECKOUT,
    STRIPE_CHECKOUT_SUCCESS,
    STRIPE_CHECKOUT_FAILURE
} from './actionTypes';

export const startStripeCheckout = (option) => ({
    type: START_STRIPE_CHECKOUT,
    payload: {option}
});

export const stripeCheckoutSuccess = () => ({
    type: STRIPE_CHECKOUT_SUCCESS,
});

export const stripeCheckoutFailure = (error) => ({
    type: STRIPE_CHECKOUT_FAILURE,
    payload: error,
});
