import {
    GET_ACTIVE_INGREDIENTS,
    GET_ACTIVE_INGREDIENTS_SUCCESS,
    GET_ACTIVE_INGREDIENTS_ERROR,
    EXPORT_ACTIVE_INGREDIENTS,
    EXPORT_ACTIVE_INGREDIENTS_SUCCESS,
    EXPORT_ACTIVE_INGREDIENTS_ERROR,
    SET_ACTIVE_INGREDIENTS_QUERY,
    SET_ACTIVE_INGREDIENTS_PAGINATION, CLEAR_ACTIVE_INGREDIENTS_STATE
} from "./actionTypes";


const initState = {
    active_ingredients: [],
    search_query: '',
    success: '',
    error: '',
    loading: false,
    exportLoading: false,
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    }
};


const ActiveIngredients = (state = initState, action) => {
    switch (action.type) {
        case GET_ACTIVE_INGREDIENTS:
            return {
                ...state,
                loading: true
            };
        case GET_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                active_ingredients: action.payload,
                loading: false
            };

        case GET_ACTIVE_INGREDIENTS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case EXPORT_ACTIVE_INGREDIENTS:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                success: "Active Ingredient was successfully exported",
                exportLoading: false
            };

        case EXPORT_ACTIVE_INGREDIENTS_ERROR:
            return {
                ...state,
                error: action.payload,
                exportLoading: false
            };
        case SET_ACTIVE_INGREDIENTS_QUERY:
            return {
                ...state,
                search_query: action.payload
            };
        case SET_ACTIVE_INGREDIENTS_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_ACTIVE_INGREDIENTS_STATE:
            return {
                ...state,
                success: '',
                error: '',
                loading: false,
                exportLoading: false
            }
        default:
            return state;
    }
}

export default ActiveIngredients;
