import {
    IMPORT_ACTIVE_INGREDIENTS,
    IMPORT_ACTIVE_INGREDIENTS_SUCCESS,
    IMPORT_ACTIVE_INGREDIENTS_ERROR,
    IMPORT_INTERMEDIATE,
    IMPORT_INTERMEDIATE_SUCCESS,
    IMPORT_INTERMEDIATE_ERROR,
    GET_IMPORT_SETTINGS,
    GET_IMPORT_SETTINGS_SUCCESS,
    GET_IMPORT_SETTINGS_ERROR,
    SETUP_AI_MAX_NUMBER,
    SETUP_AI_MAX_NUMBER_SUCCESS,
    SETUP_AI_MAX_NUMBER_ERROR,
    CLEAR_SETTINGS_STATE
} from "./actionTypes";

export const importActiveIngredients = () => ({
    type: IMPORT_ACTIVE_INGREDIENTS,
})
export const importActiveIngredientsSuccess = () => ({
    type: IMPORT_ACTIVE_INGREDIENTS_SUCCESS,
})
export const importActiveIngredientsError = (error) => ({
    type: IMPORT_ACTIVE_INGREDIENTS_ERROR,
    payload: error
})

export const importIntermediate = () => ({
    type: IMPORT_INTERMEDIATE,
})
export const importIntermediateSuccess = () => ({
    type: IMPORT_INTERMEDIATE_SUCCESS,
})
export const importIntermediateError = (error) => ({
    type: IMPORT_INTERMEDIATE_ERROR,
    payload: error
})

export const getImportSettings = () => ({
    type: GET_IMPORT_SETTINGS,
})
export const getImportSettingsSuccess = (settings) => ({
    type: GET_IMPORT_SETTINGS_SUCCESS,
    payload: settings
})
export const getImportSettingsError = (error) => ({
    type: GET_IMPORT_SETTINGS_ERROR,
    payload: error
})


export const setupAiMaxNumber = (number) => ({
    type: SETUP_AI_MAX_NUMBER,
    payload: {number}
})
export const setupAiMaxNumberSuccess = (number) => ({
    type: SETUP_AI_MAX_NUMBER_SUCCESS,
    payload: number
})
export const setupAiMaxNumberError = (error) => ({
    type: SETUP_AI_MAX_NUMBER_ERROR,
    payload: error
})

export const clearSettingsState = () => ({
    type: CLEAR_SETTINGS_STATE,
});

