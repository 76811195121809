import React from 'react';
import {Provider, useDispatch} from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import {store} from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import RootModal from '@iso/pages/Modal/RootModal';
import {openModalMain} from "./redux/rootModal/actions";
import NotificationWindow from "./components/Notifications/NotificationWindow";


const App = () => {
    return (
        <Provider store={store}>
            <AppProvider>
                <>
                    <GlobalStyles/>
                    <RootModal/>
                    <NotificationWindow/>
                    <Routes/>
                </>
            </AppProvider>
        </Provider>
    )
};
Boot()
    .then(() => App())
    .catch(error => console.error(error));

export default App;
