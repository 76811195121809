export const GET_INTER_SEARCH_LOGS = "GET_INTER_SEARCH_LOGS";
export const GET_INTER_SEARCH_LOGS_SUCCESS = "GET_INTER_SEARCH_LOGS_SUCCESS";
export const GET_INTER_SEARCH_LOGS_ERROR = "GET_INTER_SEARCH_LOGS_ERROR";

export const GET_INTER_VIEWED_LOGS = "GET_INTER_VIEWED_LOGS";
export const GET_INTER_VIEWED_LOGS_SUCCESS = "GET_INTER_VIEWED_LOGS_SUCCESS";
export const GET_INTER_VIEWED_LOGS_ERROR = "GET_INTER_VIEWED_LOGS_ERROR";

export const EXPORT_INTER_SEARCH_LOGS = "EXPORT_INTER_SEARCH_LOGS";
export const EXPORT_INTER_SEARCH_LOGS_SUCCESS = "EXPORT_INTER_SEARCH_LOGS_SUCCESS"
export const EXPORT_INTER_SEARCH_LOGS_ERROR = "EXPORT_INTER_SEARCH_LOGS_ERROR"

export const EXPORT_INTER_VIEWED_LOGS = "EXPORT_INTER_VIEWED_LOGS";
export const EXPORT_INTER_VIEWED_LOGS_SUCCESS = "EXPORT_INTER_VIEWED_LOGS_SUCCESS"
export const EXPORT_INTER_VIEWED_LOGS_ERROR = "EXPORT_INTER_VIEWED_LOGS_ERROR"

export const SET_INTER_LOGS_SEARCH_PAGINATION = "SET_INTER_LOGS_SEARCH_PAGINATION";
export const SET_INTER_LOGS_VIEWED_PAGINATION = "SET_INTER_LOGS_VIEWED_PAGINATION";

export const CLEAR_INTER_LOGS_STATE = "CLEAR_AI_LOGS_STATE";
