import React, {useEffect, useState} from "react";
import {Col, Form, Input, InputNumber, Row, Select, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {createPackage, updatePackage} from "../../../redux/admin/packages/actions";


const PackageForm = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {packages_statuses, package:edit_package, formLoading} = useSelector(state => state.Packages)
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);

    const {Option} = Select;
    const { TextArea } = Input;


    useEffect(() => {
        form.resetFields()
    }, [modalVisibility]);

    const onFinish = (data) => {
        if (Object.keys(edit_package).length > 0) {
            dispatch(updatePackage(data, edit_package.id))
        }else {
            dispatch(createPackage(data))
        }
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="package_form"
                name="package_form"
                layout="vertical"
                form={form}
                style={{width: "100%"}}
                initialValues={edit_package}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Form.Item
                            label="Option Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input name",
                                },
                            ]}
                        >
                            <Input placeholder="Option Name" name="name"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Form.Item
                            label="Option Status"
                            name="package_status_id"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a status",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Select a Status"
                            >
                                {packages_statuses.map(status => (
                                    <Option key={status.id} value={status.id}>
                                        {status.name.charAt(0).toUpperCase() + status.name.slice(1)}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Form.Item
                            label="Search Limit"
                            name="search_limit"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input search limit",
                                },
                            ]}
                        >
                            <InputNumber style={{width: "100%"}} min={1} placeholder="Search Limit" name="search_limit"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Form.Item
                            label="Price"
                            name="price"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input price",
                                },
                            ]}
                        >
                            <InputNumber min={1} placeholder="Price" name="Price" style={{width: "100%"}} addonAfter={<>$</>}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Form.Item
                            label="Description"
                            name="description"
                        >
                            <TextArea rows={4} placeholder="Description" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default PackageForm
