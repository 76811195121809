import {
    GET_PACKAGES,
    GET_PACKAGES_SUCCESS,
    GET_PACKAGES_ERROR,
    GET_PACKAGES_STATUSES,
    GET_PACKAGES_STATUSES_SUCCESS,
    GET_PACKAGES_STATUSES_ERROR,
    CREATE_PACKAGES,
    CREATE_PACKAGES_SUCCESS,
    CREATE_PACKAGES_ERROR,
    UPDATE_PACKAGES,
    UPDATE_PACKAGES_SUCCESS,
    UPDATE_PACKAGES_ERROR,
    DELETE_PACKAGES,
    DELETE_PACKAGES_SUCCESS,
    DELETE_PACKAGES_ERROR,
    STATUS_CHANGE_PACKAGES,
    STATUS_CHANGE_PACKAGES_SUCCESS,
    STATUS_CHANGE_PACKAGES_ERROR,
    SET_PACKAGE,
    CLEAR_PACKAGE_STATE,
    SET_PACKAGES_PAGINATION
} from "./actionTypes";

export const getPackages = (filters) => ({
    type: GET_PACKAGES,
    payload: {filters}
})
export const getPackagesSuccess = (packages) => ({
    type: GET_PACKAGES_SUCCESS,
    payload: packages
})
export const getPackagesError = (error) => ({
    type: GET_PACKAGES_ERROR,
    payload: error
})

export const getPackageStatuses = () => ({
    type: GET_PACKAGES_STATUSES,
})
export const getPackageStatusesSuccess = (packages) => ({
    type: GET_PACKAGES_STATUSES_SUCCESS,
    payload: packages
})
export const getPackageStatusesError = (error) => ({
    type: GET_PACKAGES_STATUSES_ERROR,
    payload: error
})


export const setPackagesPagination = (pagination) => ({
    type: SET_PACKAGES_PAGINATION,
    payload: pagination
})

export const createPackage = (packages) => ({
    type: CREATE_PACKAGES,
    payload: {packages}
})
export const createPackageSuccess = (packages) => ({
    type: CREATE_PACKAGES_SUCCESS,
    payload: packages
})
export const createPackageError = (error) => ({
    type: CREATE_PACKAGES_ERROR,
    payload: error
})

export const updatePackage = (packages, package_id) => ({
    type: UPDATE_PACKAGES,
    payload: {packages, package_id}
})
export const updatePackageSuccess = (packages) => ({
    type: UPDATE_PACKAGES_SUCCESS,
    payload: packages
})
export const updatePackageError = (error) => ({
    type: UPDATE_PACKAGES_ERROR,
    payload: error
})


export const changeStatusPackage = (package_id, status) => ({
    type: STATUS_CHANGE_PACKAGES,
    payload: {package_id, status}
})
export const changeStatusPackageSuccess = (packages) => ({
    type: STATUS_CHANGE_PACKAGES_SUCCESS,
    payload: packages
})
export const changeStatusPackageError = (error) => ({
    type: STATUS_CHANGE_PACKAGES_ERROR,
    payload: error
})


export const deletePackage = (package_id) => ({
    type: DELETE_PACKAGES,
    payload: {package_id}
})
export const deletePackageSuccess = (id) => ({
    type: DELETE_PACKAGES_SUCCESS,
    payload: id
})
export const deletePackageError = (error) => ({
    type: DELETE_PACKAGES_ERROR,
    payload: error
})

export const setPackage = (packages) => ({
    type: SET_PACKAGE,
    payload: packages
});

export const clearPackageState = () => ({
    type: CLEAR_PACKAGE_STATE,
});
