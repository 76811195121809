import { call, takeEvery, put } from 'redux-saga/effects';
import {
    GET_USER_PROFILE,
    GET_USER_PURCHASES,
    EDIT_PROFILE,
    CHANGE_PROFILE_PASSWORD, GET_USER_PACKAGES
} from "./actionTypes";
import {
    getUserProfileSuccess,
    updateUserProfileSuccess,
    updateUserProfileFail,
    getUserProfileError,
    changeProfilePasswordSuccess,
    changeProfilePasswordFail,
    getUserPurchasesSuccess,
    setUserPurchasesPagination,
    getUserPurchasesError,
    getUserPackagesSuccess,
    getUserPackagesError,
} from "./actions";

import {
    getUserProfile,
    getUserPurchasesRequest,
    updateUserProfileRequest,
    changeProfilePasswordRequest,
    getUserPackagesRequest
} from '../../library/http/backendHelpers'

function* fetchUserProfile() {
    try {
        const response = yield call(getUserProfile);
        yield put(getUserProfileSuccess(response.data));
    }catch (error) {
        yield put(getUserProfileError(error))
    }
}

function* fetchUserPurchasesSaga({payload: {filters}}) {
    try {
        const response = yield call(getUserPurchasesRequest, filters);
        yield put(setUserPurchasesPagination(response.meta));
        yield put(getUserPurchasesSuccess(response.data));
    }catch (error) {
        yield put(getUserPurchasesError(error))
    }
}

function* fetchUserPackagesSaga() {
    try {
        const response = yield call(getUserPackagesRequest);
        yield put(getUserPackagesSuccess(response.data));
    }catch (error) {
        yield put(getUserPackagesError(error))
    }
}

function* updateUserProfileSaga({ payload: {user}}) {
    try {
        const response = yield call(updateUserProfileRequest, user);
        yield put(updateUserProfileSuccess(response.data));
    }catch (error) {
        yield put(updateUserProfileFail(error))
    }
}

function* changeProfilePasswordSaga({ payload: {password} }) {
    try {
        const response = yield call(changeProfilePasswordRequest, password);
        yield put(changeProfilePasswordSuccess(response.data));
    }catch (error) {
        yield put(changeProfilePasswordFail(error))
    }
}

function* profileSaga() {
    yield takeEvery(GET_USER_PROFILE, fetchUserProfile);
    yield takeEvery(EDIT_PROFILE, updateUserProfileSaga);
    yield takeEvery(CHANGE_PROFILE_PASSWORD, changeProfilePasswordSaga);
    yield takeEvery(GET_USER_PURCHASES, fetchUserPurchasesSaga);
    yield takeEvery(GET_USER_PACKAGES, fetchUserPackagesSaga);
}

export default profileSaga
