import {
    GET_PURCHASES,
    GET_PURCHASES_SUCCESS,
    GET_PURCHASES_ERROR,
    EXPORT_PURCHASES,
    EXPORT_PURCHASES_SUCCESS,
    EXPORT_PURCHASES_ERROR,
    SET_PURCHASES_PAGINATION,
} from "./actionTypes";


const initState = {
    purchases: [],
    success: '',
    error: '',
    loading: false,
    exportLoading: false,
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};


const Purchases = (state = initState, action) => {
    switch (action.type) {
        case GET_PURCHASES:
            return {
                ...state,
                loading: true
            };
        case GET_PURCHASES_SUCCESS:
            return {
                ...state,
                purchases: action.payload,
                loading: false
            };

        case GET_PURCHASES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case EXPORT_PURCHASES:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_PURCHASES_SUCCESS:
            return {
                ...state,
                success: "File was successfully exported",
                exportLoading: false
            };
        case EXPORT_PURCHASES_ERROR:
            return {
                ...state,
                exportLoading: false,
                error: action.payload,
            };
        case SET_PURCHASES_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        default:
            return state;
    }
}

export default Purchases;
