import React from "react";
import {Col, DatePicker, Form, Row, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {exportAiSearchedLogs, exportAiViewedLogs} from "../../../redux/admin/activeIngredientsLogs/actions";
import {exportInterSearchedLogs, exportInterViewedLogs} from "../../../redux/admin/intermediatesLogs/actions";


const LogExportDateFormModal = ({type}) => {
    const {export_viewed_loading, export_searched_loading} = useSelector(state => state.AiLogs)
    const {export_viewed_loading: inter_export_viewed_loading, export_searched_loading: inter_export_searched_loading} = useSelector(state => state.InterLogs)
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleLogExport = (data) => {
        const formData = {
            start_date: moment(data.start_date).format('Y-MM-DD'),
            end_date: (data.end_date) ? moment(data.end_date).format('Y-MM-DD') : moment().format('Y-MM-DD')
        }
        console.log(type)
        // eslint-disable-next-line default-case
        switch (type){
            case 'ai_search':
                dispatch(exportAiSearchedLogs(formData))
                break;
            case 'ai_viewed':
                dispatch(exportAiViewedLogs(formData))
                break;
            case 'inter_search':
                dispatch(exportInterSearchedLogs(formData))
                break;
            case 'inter_viewed':
                dispatch(exportInterViewedLogs(formData))
                break;
        }
    }

    let isLoading;
    switch (type) {
        case 'ai_search':
            isLoading = export_searched_loading;
            break;
        case 'ai_viewed':
            isLoading = export_viewed_loading;
            break;
        case 'inter_search':
            isLoading = inter_export_viewed_loading;
            break;
        case 'inter_viewed':
            isLoading = inter_export_searched_loading;
            break;
        default:
            isLoading = false; // Default case if the type does not match
    }

    return (
        <Spin spinning={isLoading}>
            <Form form={form}
                  layout={'vertical'}
                  name="export_logs_form"
                  id="export_logs_form"
                  onFinish={handleLogExport}
            >
                <Row gutter={18}>
                    <Col span={12}>
                        <Form.Item
                            className="isoInputWrapper"
                            name="start_date"
                            label={'Select Start Date'}
                            rules={[{
                                required: true,
                                message: 'Please enter start date'
                            }]}>
                            <DatePicker style={{width: "100%"}} name={'start_date'}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="isoInputWrapper"
                            label={'Select End Date'}
                            name="end_date"
                        >
                            <DatePicker style={{width: "100%"}} name={'end_date'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default LogExportDateFormModal



