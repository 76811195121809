import {
  MENU_GET,
  MENU_SUCCESS,
  MENU_ERROR
  } from "./actionTypes";

  const initState = {
    arrayMenu: null,
    success: null,
    error: null,
    loading: false
  };


   const Menu = (state = initState, action) =>  {

    switch (action.type) {
      case MENU_GET:
        return {
          ...state,
          loading: true
        };
        
      case MENU_SUCCESS:
        return {
          ...state,
          arrayMenu: action.payload,
          success: "Menu retrieved successfully",
          loading: false
        };

      case MENU_ERROR:
        return {
          ...state,
          error: action.payload,
          loading: false
        };

      default:
        return state;
    }
  }

  export default Menu;