import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import {IMPORT_ACTIVE_INGREDIENTS, IMPORT_INTERMEDIATE, GET_IMPORT_SETTINGS, SETUP_AI_MAX_NUMBER} from "./actionTypes";
import {
    importActiveIngredientsSuccess,
    importActiveIngredientsError,
    importIntermediateSuccess,
    importIntermediateError,
    getImportSettingsSuccess,
    getImportSettingsError,
    getImportSettings,
    setupAiMaxNumberSuccess,
    setupAiMaxNumberError,
} from "./actions";

import {
    importAiRequest,
    importIntermediateRequest,
    getImportSettingsRequest,
    setupAiMaxNumberRequest
} from '../../../library/http/backendHelpers';

function* importActiveIngredientsSaga(){
    try {
        const response = yield call(importAiRequest);
        yield put(getImportSettings())
        yield put(importActiveIngredientsSuccess(response.data))
      } catch (error) {
        yield put(importActiveIngredientsError(error));
      }
}

function* importIntermediateSaga(){
    try {
        const response = yield call(importIntermediateRequest);
        yield put(getImportSettings())
        yield put(importIntermediateSuccess(response.data))
    } catch (error) {
        yield put(importIntermediateError(error));
    }
}

function* getImportSettingsSaga(){
    try {
        const response = yield call(getImportSettingsRequest);
        yield put(getImportSettingsSuccess(response.data))
    } catch (error) {
        yield put(getImportSettingsError(error));
    }
}

function* setupAiMaxNumber({ payload: {number}}){
    try {
        const response = yield call(setupAiMaxNumberRequest, number);
        yield put(setupAiMaxNumberSuccess(number.max_number))
    } catch (error) {
        yield put(setupAiMaxNumberError(error));
    }
}



function* settingsSaga() {
    yield takeEvery(IMPORT_ACTIVE_INGREDIENTS, importActiveIngredientsSaga);
    yield takeEvery(IMPORT_INTERMEDIATE, importIntermediateSaga);
    yield takeEvery(GET_IMPORT_SETTINGS, getImportSettingsSaga);
    yield takeEvery(SETUP_AI_MAX_NUMBER, setupAiMaxNumber);
  }

export default settingsSaga
