import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Menu from '@iso/redux/menu/reducer';
import Routes from '@iso/redux/routes/reducer';
import rootModal from '@iso/redux/rootModal/reducer';
import notification from '@iso/redux/notification/reducer';
import Profile from "@iso/redux/profile/reducer";
import Purchases from "./admin/purchases/reducer";
import Packages from "./admin/packages/reducer";
import Users from "./admin/users/reducer";
import AiLogs from "./admin/activeIngredientsLogs/reducer";
import InterLogs from "./admin/intermediatesLogs/reducer";
import Settings from "./admin/Settings/reducer";

import ActiveIngredients from "./user/activeIngredients/reducer";
import Intermediates from "./user/intermediates/reducer";
import Stripe from "./user/stripe/reducer";

export default combineReducers({
  App,
  LanguageSwitcher,
  ThemeSwitcher,
  Auth,
  Menu,
  Routes,
  Profile,
  Users,
  Purchases,
  Packages,
  AiLogs,
  InterLogs,
  Settings,

  ActiveIngredients,
  Intermediates,
  Stripe,

  rootModal,
  notification,
});
