import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import {EXPORT_INTERMEDIATES, GET_INTERMEDIATES} from "./actionTypes";
import {
    getIntermediatesSuccess,
    getIntermediatesError,
    exportIntermediateSuccess,
    exportIntermediateError,
    setIntermediatePagination
} from "./actions";

import {getIntermediatesRequest, exportIntermediatesRequest} from '../../../library/http/backendHelpers';
import moment from "moment/moment";
import {setAuthState} from "../../auth/actions";

function* getIntermediatesSaga({payload: {filters}}){
    try {
        const response = yield call(getIntermediatesRequest, filters);
        yield put(setIntermediatePagination(response.meta))
        yield put(getIntermediatesSuccess(response.data))
      } catch (error) {
        yield put(getIntermediatesError(error));
      }
}

function* exportIntermediatesSaga({payload: {id, name}}){
    try {
        const filename  = 'intermediate-'+name+'-'+ moment().format('DDMMMYYYY') + '.pdf';
        const response = yield call(exportIntermediatesRequest, id, filename);
        yield put(setAuthState());
        yield put(exportIntermediateSuccess())
    } catch (error) {
        yield put(exportIntermediateError(error));
    }
}

function* intermediatesSaga() {
    yield takeEvery(GET_INTERMEDIATES, getIntermediatesSaga);
    yield takeEvery(EXPORT_INTERMEDIATES, exportIntermediatesSaga);
}

export default intermediatesSaga
