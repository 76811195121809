import {
    START_STRIPE_CHECKOUT,
    STRIPE_CHECKOUT_SUCCESS,
    STRIPE_CHECKOUT_FAILURE
} from './actionTypes';

const initialState = {
    stripeLoading: false,
    error: null,
    success: false,
};

const Stripe = (state = initialState, action) => {
    switch (action.type) {
        case START_STRIPE_CHECKOUT:
            return {
                ...state,
                stripeLoading: true,
                error: null,
                success: false,
            };
        case STRIPE_CHECKOUT_SUCCESS:
            return {
                ...state,
                stripeLoading: false,
                success: true,
                error: null,
            };
        case STRIPE_CHECKOUT_FAILURE:
            return {
                ...state,
                stripeLoading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default Stripe;

