import {
    GET_AI_SEARCH_LOGS,
    GET_AI_SEARCH_LOGS_SUCCESS,
    GET_AI_SEARCH_LOGS_ERROR,
    GET_AI_VIEWED_LOGS,
    GET_AI_VIEWED_LOGS_SUCCESS,
    GET_AI_VIEWED_LOGS_ERROR,
    EXPORT_AI_SEARCH_LOGS,
    EXPORT_AI_SEARCH_LOGS_SUCCESS,
    EXPORT_AI_SEARCH_LOGS_ERROR,
    EXPORT_AI_VIEWED_LOGS,
    EXPORT_AI_VIEWED_LOGS_SUCCESS,
    EXPORT_AI_VIEWED_LOGS_ERROR,
    SET_AI_LOGS_SEARCH_PAGINATION,
    SET_AI_LOGS_VIEWED_PAGINATION,
    CLEAR_AI_LOGS_STATE
} from "./actionTypes";

const initState = {
    ai_logs_searched: [],
    ai_logs_viewed: [],
    success: '',
    error: '',
    loading_searched: false,
    loading_viewed: false,
    export_searched_loading: false,
    export_viewed_loading: false,
    pagination_searched: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    pagination_viewed: {
        current: 1,
        pageSize: 0,
        total: 0
    }
};

const AiLogs = (state = initState, action) => {
    switch (action.type) {
        case GET_AI_SEARCH_LOGS:
            return {
                ...state,
                loading_searched: true
            };
        case GET_AI_SEARCH_LOGS_SUCCESS:
            return {
                ...state,
                ai_logs_searched: action.payload,
                loading_searched: false
            };
        case GET_AI_SEARCH_LOGS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading_searched: false
            };

        case GET_AI_VIEWED_LOGS:
            return {
                ...state,
                loading_viewed: true
            };
        case GET_AI_VIEWED_LOGS_SUCCESS:
            return {
                ...state,
                ai_logs_viewed: action.payload,
                loading_viewed: false
            };
        case GET_AI_VIEWED_LOGS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading_viewed: false
            };


        case EXPORT_AI_SEARCH_LOGS:
            return {
                ...state,
                export_searched_loading: true
            };
        case EXPORT_AI_SEARCH_LOGS_SUCCESS:
            return {
                ...state,
                success: "File was successfully exported",
                export_searched_loading: false
            };
        case EXPORT_AI_SEARCH_LOGS_ERROR:
            return {
                ...state,
                export_searched_loading: false,
                error: action.payload,
            };

        case EXPORT_AI_VIEWED_LOGS:
            return {
                ...state,
                export_viewed_loading: true
            };
        case EXPORT_AI_VIEWED_LOGS_SUCCESS:
            return {
                ...state,
                success: "File was successfully exported",
                export_viewed_loading: false
            };
        case EXPORT_AI_VIEWED_LOGS_ERROR:
            return {
                ...state,
                export_viewed_loading: false,
                error: action.payload,
            };

        case SET_AI_LOGS_SEARCH_PAGINATION:
            return {
                ...state,
                pagination_searched: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };

        case SET_AI_LOGS_VIEWED_PAGINATION:
            return {
                ...state,
                pagination_viewed: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_AI_LOGS_STATE:
            return {
                ...state,
                success: '',
                error: '',
                loading_searched: false,
                loading_viewed: false,
                export_searched_loading: false,
                export_viewed_loading: false,
            };
        default:
            return state;
    }
}

export default AiLogs;
