import {
    GET_INTER_SEARCH_LOGS,
    GET_INTER_SEARCH_LOGS_SUCCESS,
    GET_INTER_SEARCH_LOGS_ERROR,
    GET_INTER_VIEWED_LOGS,
    GET_INTER_VIEWED_LOGS_SUCCESS,
    GET_INTER_VIEWED_LOGS_ERROR,
    EXPORT_INTER_SEARCH_LOGS,
    EXPORT_INTER_SEARCH_LOGS_SUCCESS,
    EXPORT_INTER_SEARCH_LOGS_ERROR,
    EXPORT_INTER_VIEWED_LOGS,
    EXPORT_INTER_VIEWED_LOGS_SUCCESS,
    EXPORT_INTER_VIEWED_LOGS_ERROR,
    SET_INTER_LOGS_SEARCH_PAGINATION,
    SET_INTER_LOGS_VIEWED_PAGINATION,
    CLEAR_INTER_LOGS_STATE
} from "./actionTypes";


export const getInterSearchLogs = (filters) => ({
    type: GET_INTER_SEARCH_LOGS,
    payload: {filters}
})
export const getInterSearchLogsSuccess = (logs) => ({
    type: GET_INTER_SEARCH_LOGS_SUCCESS,
    payload: logs
})
export const getInterSearchLogsError = (error) => ({
    type: GET_INTER_SEARCH_LOGS_ERROR,
    payload: error
})

export const getInterViewedLogs = (filters) => ({
    type: GET_INTER_VIEWED_LOGS,
    payload: {filters}
})
export const getInterViewedLogsSuccess = (logs) => ({
    type: GET_INTER_VIEWED_LOGS_SUCCESS,
    payload: logs
})
export const getInterViewedLogsError = (error) => ({
    type: GET_INTER_VIEWED_LOGS_ERROR,
    payload: error
})

export const exportInterSearchedLogs = (dates) => ({
    type: EXPORT_INTER_SEARCH_LOGS,
    payload: { dates }
})

export const exportInterSearchedLogsSuccess = () => ({
    type: EXPORT_INTER_SEARCH_LOGS_SUCCESS,
});

export const exportInterSearchedLogsError = error => ({
    type: EXPORT_INTER_SEARCH_LOGS_ERROR,
    payload: error,
});

export const exportInterViewedLogs = (dates) => ({
    type: EXPORT_INTER_VIEWED_LOGS,
    payload: { dates }
})

export const exportInterViewedLogsSuccess = () => ({
    type: EXPORT_INTER_VIEWED_LOGS_SUCCESS,
});

export const exportInterViewedLogsError = error => ({
    type: EXPORT_INTER_VIEWED_LOGS_ERROR,
    payload: error,
});

export const setInterLogsSearchedPagination = (pagination) => ({
    type: SET_INTER_LOGS_SEARCH_PAGINATION,
    payload: pagination
})

export const setInterLogsViewedPagination = (pagination) => ({
    type: SET_INTER_LOGS_VIEWED_PAGINATION,
    payload: pagination
})

export const clearInterLogsState = () => ({
    type: CLEAR_INTER_LOGS_STATE,
});
