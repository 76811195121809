import {del, downloadFile, get, post, put} from "./apiHelpers";
import * as url from './urlHelpers';
import {
    ACTIVE_INGREDIENTS, AI_SEARCH_LOGS, AI_VIEWED_LOGS,
    IMPORT_SETTINGS, INTER_SEARCH_LOGS, INTER_VIEWED_LOGS, INTERMEDIATES,
    PACKAGES,
    PASSWORD_CHANGE,
    PURCHASES,
    SETUP_MAX_NUMBER, STRIPE_CHECKOUT,
    USER_PACKAGES,
    USER_PURCHASES
} from "./urlHelpers";

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
// Register Method
const postRegister = data => post(url.POST_REGISTER, data);
//Verify Email
const verifyEmailRequest = (user_id, hash, signature) => get(`${url.VERIFY_EMAIL_URL}/${user_id}/${hash}?signature=${signature}`);
//resend verification email
const resendVerificationEmail = () => post(url.RESEND_VERIFICATION_EMAIL);
//resend verification email
const resetPasswordRequest = (data) => post(url.PASSWORD_CHANGE, data);
//forgot email
const forgotPasswordRequest = (data) => post(url.FORGOT_PASSWORD, data);
//password reset
const passwordResetRequest = (data) => post(url.PASSWORD_RESET, data);
// User State Method
const getUserState = () => get(url.GET_USER_STATE);
// Menu Methods
const getMenu = data => get(url.GET_MENU);
// Route Methods
const getRoutes = data => get(url.GET_ROUTES);
//Users
const getUsers = (filters) => get(url.USERS, {params: filters});
const createUsers = (user) => post(url.USERS, user);
const editUser = (user, user_id) => put(`${url.USERS}/${user_id}`, user);
const deleteUserRequest = (user_id) => del(`${url.USERS}/${user_id}`);
const verifyUserRequest = (user_id) => get(`${url.USERS}/${user_id}/verify`)
const sentVerificationEmailRequest = (user_id) => get(`${url.USERS}/${user_id}/sent-verification-email`)
const changeUserPasswordRequest = (password, user_id) => put(`${url.USERS}/${user_id}/set-password`, password)
const exportUsersRequest = (dates, filename) => downloadFile(`${url.USERS}/export`, {params: dates}, filename)
const userAdditionalSearchesRequest = (searches) => post(`${url.USERS}/add-searches`, searches)

const getUserProfile = () => get(url.PROFILE)
const updateUserProfileRequest = (user) => put(url.PROFILE, user)
const changeProfilePasswordRequest = (password) => post(url.PASSWORD_CHANGE, password)
const getUserPurchasesRequest = (filters) => get(url.USER_PURCHASES, {params: filters})
const getUserPackagesRequest = () => get(url.USER_PACKAGES)

//Purchases
const getPurchasesRequest = (filters) => get(url.PURCHASES, {params: filters})
const exportPurchasesRequest= (dates, filename) => downloadFile(`${url.PURCHASES}/export`, {params: dates}, filename)

//packages
const getPackagesRequest = (filters) => get(url.PACKAGES, {params: filters})
const createPackagesRequest = (packages) => post(url.PACKAGES, packages)
const updatePackagesRequest = (packages, package_id) => put(`${url.PACKAGES}/${package_id}`, packages)
const statusChangePackagesRequest = (package_id, status) => put(`${url.PACKAGES}-statuses-change/${package_id}`, status)
const deletePackagesRequest = (package_id) => del(`${url.PACKAGES}/${package_id}`)
const getPackagesStatusesRequest = () => get(`${url.PACKAGES}-statuses`)

//Logs
const getAiSearchedLogsRequest = (filters) => get(`${url.AI_SEARCH_LOGS}`, {params: filters})
const exportAiSearchedLogsRequest = (dates, filename) => downloadFile(`${url.AI_SEARCH_LOGS}/export`, {params: dates}, filename)
const getAiViewedLogsRequest = (filters) => get(`${url.AI_VIEWED_LOGS}`, {params: filters})
const exportAiViewedLogsRequest = (dates, filename) => downloadFile(`${url.AI_VIEWED_LOGS}/export`, {params: dates}, filename)
//inter
const getInterSearchedLogsRequest = (filters) => get(`${url.INTER_SEARCH_LOGS}`, {params: filters})
const getInterViewedLogsRequest = (filters) => get(`${url.INTER_VIEWED_LOGS}`, {params: filters})
const exportInterSearchedLogsRequest = (dates, filename) => downloadFile(`${url.INTER_SEARCH_LOGS}/export`, {params: dates}, filename)
const exportInterViewedLogsRequest = (dates, filename) => downloadFile(`${url.INTER_VIEWED_LOGS}/export`, {params: dates}, filename)
//Imports
const importAiRequest = () => get(url.IMPORT_AI)
const importIntermediateRequest = () => get(url.IMPORT_INTERMEDIATE)
const getImportSettingsRequest = () => get(url.IMPORT_SETTINGS)
const setupAiMaxNumberRequest = (number) => post(url.SETUP_MAX_NUMBER, number)

//Business logic here

const getActiveIngredientsRequest = (filters) => get(url.ACTIVE_INGREDIENTS, {params: filters})
const exportActiveIngredientsRequest = (id, filename) => downloadFile(`${url.ACTIVE_INGREDIENTS}/${id}/export`, null, filename)
const getIntermediatesRequest = (filters) => get(url.INTERMEDIATES, {params: filters})
const exportIntermediatesRequest = (id, filename) => downloadFile(`${url.INTERMEDIATES}/${id}/export`, null, filename)

//Stripe

const checkoutWithStripe = (option) => post(url.STRIPE_CHECKOUT, Option)

export {
    postLogin,
    postRegister,
    verifyEmailRequest,
    resendVerificationEmail,
    resetPasswordRequest,
    getUserState,
    getMenu,
    getRoutes,
    passwordResetRequest,
    forgotPasswordRequest,
    updateUserProfileRequest,
    changeProfilePasswordRequest,
    //users
    getUsers,
    createUsers,
    editUser,
    deleteUserRequest,
    getUserProfile,
    verifyUserRequest,
    sentVerificationEmailRequest,
    changeUserPasswordRequest,
    exportUsersRequest,
    userAdditionalSearchesRequest,
    getUserPurchasesRequest,
    getUserPackagesRequest,
    // purchases
    getPurchasesRequest,
    exportPurchasesRequest,
    // packages
    getPackagesRequest,
    createPackagesRequest,
    updatePackagesRequest,
    statusChangePackagesRequest,
    deletePackagesRequest,
    getPackagesStatusesRequest,
    //Logs
    getAiSearchedLogsRequest,
    exportAiSearchedLogsRequest,
    getAiViewedLogsRequest,
    exportAiViewedLogsRequest,
    //inter
    getInterSearchedLogsRequest,
    getInterViewedLogsRequest,
    exportInterSearchedLogsRequest,
    exportInterViewedLogsRequest,
    //Imports
    importAiRequest,
    importIntermediateRequest,
    getImportSettingsRequest,
    setupAiMaxNumberRequest,

    //Business logic here
    getActiveIngredientsRequest,
    getIntermediatesRequest,
    exportActiveIngredientsRequest,
    exportIntermediatesRequest,

    //stripe
    checkoutWithStripe
}
