export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";

export const GET_USER_PURCHASES = "GET_USER_PURCHASES";
export const GET_USER_PURCHASES_SUCCESS = "GET_USER_PURCHASES_SUCCESS";
export const GET_USER_PURCHASES_ERROR = "GET_USER_PURCHASES_ERROR"

export const GET_USER_PACKAGES = "GET_USER_PACKAGES";
export const GET_USER_PACKAGES_SUCCESS = "GET_USER_PACKAGES_SUCCESS";
export const GET_USER_PACKAGES_ERROR = "GET_USER_PACKAGES_ERROR"

export const SET_USER_PURCHASES_PAGINATION = "SET_USER_PURCHASES_PAGINATION"

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL";

export const CHANGE_PROFILE_PASSWORD = "CHANGE_PROFILE_PASSWORD";
export const CHANGE_PROFILE_PASSWORD_SUCCESS = "CHANGE_PROFILE_PASSWORD_SUCCESS";
export const CHANGE_PROFILE_PASSWORD_FAIL = "CHANGE_PROFILE_PASSWORD_FAIL";

export const CLEAR_USER_PROFILE_STATE = "CLEAR_USER_PROFILE_STATE";
