import { call, takeEvery, put } from 'redux-saga/effects';
import {
    getUsers,
    createUsers,
    editUser,
    deleteUserRequest,
    verifyUserRequest,
    sentVerificationEmailRequest,
    changeUserPasswordRequest,
    exportUsersRequest,
    userAdditionalSearchesRequest
} from "../../../library/http/backendHelpers";
import {
    getUsersError,
    getUsersSuccess,
    setUsersPagination,
    createUserSuccess,
    createUserError,
    updateUserSuccess,
    updateUserError,
    deleteUserSuccess,
    deleteUserError,
    verifyUserSuccess,
    verifyUserError,
    sentVerifyEmailSuccess,
    sentVerifyEmailError,
    changeUserPasswordSuccess,
    changeUserPasswordError,
    exportUsersError,
    exportUsersSuccess,
    userAdditionalSearchesSuccess,
    userAdditionalSearchesError
} from "./actions";
import {
    GET_USERS,
    UPDATE_USER,
    CREATE_USER,
    DELETE_USER,
    VERIFY_USER,
    SENT_VERIFY_EMAIL,
    CHANGE_USER_PASSWORD,
    EXPORT_USERS,
    ADD_USER_ADDITIONAL_SEARCHES
} from "./actionTypes";
import moment from "moment";

function* getUsersSaga({ payload: {filter} }){
    try {
        const response = yield call(getUsers, filter);
        yield put(setUsersPagination(response.meta));
        yield put(getUsersSuccess(response.data));
    } catch (error) {
        yield put(getUsersError(error));
    }
}

function* createUser({payload: {user}}) {
    try {
        const response = yield call(createUsers, user);
        yield put(createUserSuccess(response.data));
    } catch (error) {
        yield put(createUserError(error))
    }
}

function* updateUser({payload: {user, user_id}}) {
    try {
        const response = yield call(editUser, user, user_id);
        yield put(updateUserSuccess(response.data));
    } catch (error) {
        yield put(updateUserError(error))
    }
}

function* deleteUser({ payload: {user_id}}) {
    try {
        const response = yield call(deleteUserRequest, user_id);
        yield put(deleteUserSuccess(user_id));
    }catch (error) {
        yield put(deleteUserError(error))
    }
}

function* verifyUserSaga({ payload: {user_id}}) {
    try {
        const response = yield call(verifyUserRequest, user_id);
        yield put(verifyUserSuccess(response.data));
    }catch (error) {
        yield put(verifyUserError(error))
    }
}

function* sentVerificationEmailSaga({ payload: {user_id}}) {
    try {
        const response = yield call(sentVerificationEmailRequest, user_id);
        yield put(sentVerifyEmailSuccess(response.data));
    }catch (error) {
        yield put(sentVerifyEmailError(error))
    }
}


function* changeUserPasswordSaga({payload: {password, user_id}}) {
    try {
        const response = yield call(changeUserPasswordRequest, password, user_id);
        yield put(changeUserPasswordSuccess(response.message));
    }catch (error) {
        yield put(changeUserPasswordError(error))
    }
}

function* exportUsersSaga({ payload: { dates }}) {
    try {
        const filename  = 'users-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportUsersRequest, dates, filename);
        yield put(exportUsersSuccess());
    }catch (error) {
        yield put(exportUsersError(error))
    }
}

function* userAdditionalSearchesSaga({payload: {searches}}) {
    try {
        const response = yield call(userAdditionalSearchesRequest, searches);
        yield put(userAdditionalSearchesSuccess(response.data));
    } catch (error) {
        yield put(userAdditionalSearchesError(error))
    }
}


function* usersSaga() {
    yield takeEvery(GET_USERS, getUsersSaga);
    yield takeEvery(CREATE_USER, createUser);
    yield takeEvery(UPDATE_USER, updateUser);
    yield takeEvery(DELETE_USER, deleteUser);
    yield takeEvery(VERIFY_USER, verifyUserSaga);
    yield takeEvery(SENT_VERIFY_EMAIL, sentVerificationEmailSaga);
    yield takeEvery(CHANGE_USER_PASSWORD, changeUserPasswordSaga);
    yield takeEvery(EXPORT_USERS, exportUsersSaga);
    yield takeEvery(ADD_USER_ADDITIONAL_SEARCHES, userAdditionalSearchesSaga);
}

export default usersSaga
