import {
    GET_USERS,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,

    VERIFY_USER,
    VERIFY_USER_SUCCESS,
    VERIFY_USER_ERROR,
    SENT_VERIFY_EMAIL,
    SENT_VERIFY_EMAIL_SUCCESS,
    SENT_VERIFY_EMAIL_ERROR,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_SUCCESS,
    CHANGE_USER_PASSWORD_ERROR,

    EXPORT_USERS,
    EXPORT_USERS_SUCCESS,
    EXPORT_USERS_ERROR,

    ADD_USER_ADDITIONAL_SEARCHES,
    ADD_USER_ADDITIONAL_SEARCHES_SUCCESS,
    ADD_USER_ADDITIONAL_SEARCHES_ERROR,

    SET_USER_PAGINATION,
    SET_USER,
    CLEAR_USER_STATE
} from "./actionTypes";

export const getUsers = (filter) => ({
    type: GET_USERS,
    payload: {filter}
});

export const getUsersSuccess = (data) => ({
    type: GET_USERS_SUCCESS,
    payload: data
});

export const getUsersError = (error) => ({
    type: GET_USERS_ERROR,
    payload: error
});


export const createUser = (user) => ({
    type: CREATE_USER,
    payload: {user}
});

export const createUserSuccess = (data) => ({
    type: CREATE_USER_SUCCESS,
    payload: data
});

export const createUserError = (error) => ({
    type: CREATE_USER_ERROR,
    payload: error
});

export const updateUser = (user, user_id) => ({
    type: UPDATE_USER,
    payload: {user, user_id}
});

export const updateUserSuccess = user => ({
    type: UPDATE_USER_SUCCESS,
    payload: user,
});

export const updateUserError = error => ({
    type: UPDATE_USER_ERROR,
    payload: error,
});

export const deleteUser = (user_id) => ({
    type: DELETE_USER,
    payload: {user_id}
});

export const deleteUserSuccess = (id) => ({
    type: DELETE_USER_SUCCESS,
    payload: id
});

export const deleteUserError = (error) => ({
    type: DELETE_USER_ERROR,
    payload: error
});

export const verifyUser = (user_id) => ({
    type: VERIFY_USER,
    payload: {user_id}
});

export const verifyUserSuccess = (user) => ({
    type: VERIFY_USER_SUCCESS,
    payload: user,
});

export const verifyUserError = (error) => ({
    type: VERIFY_USER_ERROR,
    payload: error
});

export const sentVerifyEmail = (user_id) => ({
    type: SENT_VERIFY_EMAIL,
    payload: {user_id}
});

export const sentVerifyEmailSuccess = (user) => ({
    type: SENT_VERIFY_EMAIL_SUCCESS,
    payload: user,
});

export const sentVerifyEmailError = (error) => ({
    type: SENT_VERIFY_EMAIL_ERROR,
    payload: error
});

export const changeUserPassword = (password, user_id) => ({
    type: CHANGE_USER_PASSWORD,
    payload: {password, user_id}
});

export const changeUserPasswordSuccess = data => ({
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload: data,
});

export const changeUserPasswordError = error => ({
    type: CHANGE_USER_PASSWORD_ERROR,
    payload: error,
});

export const exportUsers = (dates) => ({
    type: EXPORT_USERS,
    payload: { dates }
})

export const exportUsersSuccess = () => ({
    type: EXPORT_USERS_SUCCESS,
});

export const exportUsersError = error => ({
    type: EXPORT_USERS_ERROR,
    payload: error,
});

export const userAdditionalSearches = (searches) => ({
    type: ADD_USER_ADDITIONAL_SEARCHES,
    payload: {searches}
});

export const userAdditionalSearchesSuccess = (data) => ({
    type: ADD_USER_ADDITIONAL_SEARCHES_SUCCESS,
    payload: data
});

export const userAdditionalSearchesError = (error) => ({
    type: ADD_USER_ADDITIONAL_SEARCHES_ERROR,
    payload: error
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user
});

export const setUsersPagination = pagination => ({
    type: SET_USER_PAGINATION,
    payload: pagination
});


export const clearUserState = () => ({
    type: CLEAR_USER_STATE,
});
