import React, {useEffect, useState} from "react";
import {
    Switch,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Spin,
    Space,
    Typography,
    Tooltip,
    Checkbox
} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {countries_code} from "../../../library/helpers/countires_code";
import {countries} from "../../../config/data/countries";
import {InfoCircleOutlined} from '@ant-design/icons'
import {clearUserState, createUser, updateUser} from "../../../redux/admin/users/actions";
import {validateFromServer} from "../../../library/helpers/errorMessageParse";

const UserForm = () => {
    const dispatch = useDispatch();
    const {user, formLoading, error } = useSelector(state => state.Users);
    const [form] = Form.useForm()
    const {Option} = Select
    const {Text} = Typography;
    const [passwordManually, setPasswordManually] = useState(false)
    const [trackLogs, setTrackLogs] = useState(true);
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);

    const options = countries_code.map((country) => ({
        label:  `${country.name} ${(country.dial_code)}`,
        value: `${country.dial_code}-${country.code}`
    }));

    useEffect(() => {
        form.resetFields()
    }, [modalVisibility]);

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            setTrackLogs((user.track_log === 1))
        }else{
            setTrackLogs(true)
        }
    }, [user])
    const onFinish = (data) => {
        const formData = {
            first_name: data.first_name,
            last_name: data.last_name,
            company: data.company,
            password: (data.password) ? data.password : null,
            job_title: (data.job_title) ? data.job_title : '',
            country_code: data.country_code.split('-')[0],
            phone_number: data.phone_number,
            email: data.email,
            primary_address: (data.primary_address) ? data.primary_address : '',
            secondary_address: (data.secondary_address) ? data.secondary_address : '',
            city: (data.city) ? data.city : '',
            state: (data.state) ? data.state : '',
            postal_code: (data.postal_code) ? data.postal_code : '',
            country: (data.country) ? data.country : '',
            track_log: (trackLogs === true) ? 1 : 0,
            user_type: data.user_type,
        }
        if (Object.keys(user).length > 0) {
            dispatch(updateUser(formData, user.id))
        }else {
            dispatch(createUser(formData))
        }
    }

    if (error) {
        if (error.status === 422) {
            form.setFields(validateFromServer(error));
        }
        dispatch(clearUserState());
    }

    return (
        <Spin spinning={formLoading}>
            <Form
                id="user_form"
                name="user_form"
                layout="vertical"
                form={form}
                style={{ width: "100%" }}
                onFinish={onFinish}
                initialValues={(Object.keys(user).length > 0) ? user : {user_type: 2}}
            >
                <Row gutter={16}>
                    <Col md={24} sm={12} xs={24} style={{marginBottom: 0}}>
                        <Row gutter={16}>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input first name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="First Name" name="first_name" />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input last name",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Last Name" name="last_name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Company"
                                    name="company"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input company",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Company" name="company" />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                                <Form.Item
                                    label="Job Title"
                                    name="job_title"
                                >
                                    <Input placeholder="Job Title" name="job_title" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={6} sm={6} xs={6}>
                                <Form.Item
                                    label="Country Code"
                                    name="country_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input country code",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a country"
                                        options={options}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={18} sm={18} xs={18}>
                                <Form.Item
                                    label="Phone Number"
                                    name="phone_number"
                                    rules={[
                                        { required: true, message: 'Please input your phone number' },
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (getFieldValue('phone_number').length <= 8) {
                                                    return Promise.reject('Phone number length should be greater than 8.');
                                                }
                                                if (getFieldValue('phone_number').length >= 15) {
                                                    return Promise.reject('Phone number length should be smaller than 15.');
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <InputNumber style={{width: "100%"}} placeholder="Phone Number" name="phone_number" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={16} sm={16} xs={16}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: (Object.keys(user).length === 0),
                                            message: "Please input email",
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" name="email" disabled={(Object.keys(user).length > 0)}/>
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={8}>
                                <Form.Item
                                    label="User Type"
                                    name="user_type"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please choose a user type",
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a Type"
                                        defaultValue={1}
                                        options={[
                                            {
                                                value: 1,
                                                label: 'Admin',
                                            },
                                            {
                                                value: 2,
                                                label: 'User',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {(Object.keys(user).length === 0) &&
                            <>
                                <Divider style={{marginTop: "10px", marginBottom: "15px"}}/>
                                <Row gutter={24}>
                                    <Col md={6} sm={6} xs={6} style={{display: "flex", justifyContent: "left"}}>
                                        <Space direction={'vertical'}>
                                            <Text>Setup Password Manually
                                                <Tooltip
                                                    title="If you don't set the password manually, an email will be sent to the user to set the password themselves">
                                                    <InfoCircleOutlined style={{marginLeft: "2px"}}/>
                                                </Tooltip>
                                            </Text>
                                            <Switch onChange={(checked) => setPasswordManually(checked)}
                                                    style={{marginTop: "6px"}}/>
                                        </Space>
                                    </Col>
                                    <Col md={18} sm={18} xs={18}>
                                        <Form.Item
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: passwordManually,
                                                    message: "Please input password",
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder="Password" disabled={(!passwordManually)}
                                                            name="password"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div style={{fontSize: '13px'}}><span>Password must:</span><br/>
                                    - Be a minimum of 10 characters <br/>
                                    - Include at least one lowercase letter (a-z) <br/>
                                    - Include at least one uppercase letter (A-Z) <br/>
                                    - Include at least one number (0-9) <br/>
                                    - Include at least one special character
                                </div>
                            </>
                        }
                        <Divider orientation={'left'}>Address</Divider>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    name="primary_address"
                                >
                                    <Input placeholder="Primary Address" name="primary_address"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    name="secondary_address"
                                >
                                    <Input placeholder="Secondary Address" name="secondary_address" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={8} sm={8} xs={8}>
                                <Form.Item
                                    name="city"
                                >
                                    <Input placeholder="City" name="city" />
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={8}>
                                <Form.Item
                                    name="state"
                                >
                                    <Input placeholder="State" name="state" />
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={8}>
                                <Form.Item
                                    name="postal_code"
                                >
                                    <Input placeholder="Postal Code" name="postal_code" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col md={16} sm={16} xs={16}>
                                <Form.Item
                                    name="country"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select a country"
                                    >
                                        {countries.map(country => (
                                            <Option key={country.value} value={country.value}>
                                                {country.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={8} sm={8} xs={8} style={{marginTop: "5px"}}>
                                <Checkbox checked={trackLogs} onChange={(e) => setTrackLogs(e.target.checked)}>Track Logs for this user</Checkbox>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default UserForm
