import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {closeModalMain} from '@iso/redux/rootModal/actions';
import userForm from "../Users/Components/UserForm";
import ChangePasswordForm from "../Users/Components/ChangePasswordForm";
import UserProfileModal from "../Users/Components/UserProfileModal";
import ExportUserModal from "../Users/Components/ExportUserModal";
import PurchaseExportModal from "../Purchases/Components/PurchaseExportModal";
import PackageForm from "../Settings/Components/PackageForm";
import ChangeProfilePasswordForm from "../Profile/Components/ChangeProfilePasswordForm";
import AdditionalSearchFormModal from "../Profile/Components/AdditionalSearchFormModal";
import IntermediatesViewModal from "../../main/pages/Intermediates/Components/IntermediatesViewModal";
import ActiveIngredientViewModal from "../../main/pages/ActiveIngredients/Components/ActiveIngredientViewModal";
import UserAdditionalSearchesForm from "../Users/Components/UserAdditionalSearchesForm";
import LogExportDateFormModal from "../Logs/Components/LogExportDateFormModal";
import NoRemainingSearchModal from "../../main/pages/NoRemainingSearchModal";

const MODAL_COMPONENTS = {
  USER_FORM_MODAL: userForm,
  CHANGE_USER_PASSWORD_FORM: ChangePasswordForm,
  USER_PROFILE_MODAL: UserProfileModal,
  USER_EXPORT_MODAL: ExportUserModal,
  USER_ADDITIONAL_SEARCH_MODAL: UserAdditionalSearchesForm,
  NO_REMAINING_SEARCH_MODAL: NoRemainingSearchModal,
  PURCHASES_EXPORT_MODAL: PurchaseExportModal,
  PACKAGE_FORM_MODAL: PackageForm,

  PROFILE_PASSWORD_MODAL: ChangeProfilePasswordForm,
  ADDITIONAL_SEARCH_MODAL: AdditionalSearchFormModal,

  ACTIVE_INGREDIENT_VIEW_MODEL: ActiveIngredientViewModal,
  INTERMEDIATES_VIEW_MODEL: IntermediatesViewModal,

  EXPORT_LOGS_MODAL: LogExportDateFormModal

};


const RootModal = () => {

  const dispatch = useDispatch();
  const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);
  const modalProps = useSelector((state) => state.rootModal.modalProps);
  const modalType = useSelector((state) => state.rootModal.modalType);
  const modalBodyProps = useSelector((state) => state.rootModal.modalBodyProps);

  const SpecificModal = MODAL_COMPONENTS[modalType];
    return(
      <Modal
        visible={modalVisibility}
        onCancel={() => dispatch(closeModalMain())}
       {...modalProps}
      >
        <SpecificModal  {...modalBodyProps} onCancel={() => dispatch(closeModalMain())} />
      </Modal>
    );

}


export default RootModal;
