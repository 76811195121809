import React, {useEffect} from "react";
import {Alert, Form, Input, Select, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getUserPackages} from "../../../redux/profile/actions";
import {startStripeCheckout} from "../../../redux/user/stripe/actions";

const AdditionalSearchFormModal = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { packages, formLoading } = useSelector(state => state.Profile)
    const { stripeLoading } = useSelector(state => state.Stripe)
    const {Option} = Select;
    const {Text} = Typography

    useEffect(() => {
        if (packages.length === 0) {
            dispatch(getUserPackages())
        }
    }, [])

    const handleAdditionalSearch = (package_id) => {
        dispatch(startStripeCheckout(package_id))
    }



    return (
        <Spin spinning={stripeLoading}>
            <Form form={form}
                  name="additional_search_form"
                  id="additional_search_form"
                  layout={'vertical'}
                  onFinish={handleAdditionalSearch}
            >
                <Form.Item
                    className="isoInputWrapper"
                    label="Select Package"
                    name="package"
                    rules={[{
                        required: true,
                        message: 'Please select a package'
                    }]}>
                    <Select
                        showSearch
                        placeholder="Select a Package"
                        size={'large'}
                    >
                        {packages.map(item => (
                            <Option key={item.id} value={item.id}>
                                {item.name}:  {item.search_limit} Searches - {Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD',}).format(item.price)}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Alert style={{marginTop: "15px"}} message={
                    <Text type={'secondary'}>
                        For Unlimited Searches - contact us at <a target="_blank" href="mailto:sales@beckerdata.com">sales@beckerdata.com</a>
                    </Text>
                } type="warning" />
            </Form>
        </Spin>
    )
}

export default AdditionalSearchFormModal
