export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_ERROR = "GET_PACKAGES_ERROR";

export const GET_PACKAGES_STATUSES = "GET_PACKAGES_STATUSES";
export const GET_PACKAGES_STATUSES_SUCCESS = "GET_PACKAGES_STATUSES_SUCCESS";
export const GET_PACKAGES_STATUSES_ERROR = "GET_PACKAGES_STATUSES_ERROR";

export const CREATE_PACKAGES = "CREATE_PACKAGES";
export const CREATE_PACKAGES_SUCCESS = "CREATE_PACKAGES_SUCCESS";
export const CREATE_PACKAGES_ERROR = "CREATE_PACKAGES_ERROR";


export const UPDATE_PACKAGES = "UPDATE_PACKAGES";
export const UPDATE_PACKAGES_SUCCESS = "UPDATE_PACKAGES_SUCCESS";
export const UPDATE_PACKAGES_ERROR = "UPDATE_PACKAGES_ERROR";


export const DELETE_PACKAGES = "DELETE_PACKAGES";
export const DELETE_PACKAGES_SUCCESS = "DELETE_PACKAGES_SUCCESS";
export const DELETE_PACKAGES_ERROR = "DELETE_PACKAGES_ERROR";


export const STATUS_CHANGE_PACKAGES = "STATUS_CHANGE_PACKAGES";
export const STATUS_CHANGE_PACKAGES_SUCCESS = "STATUS_CHANGE_PACKAGES_SUCCESS";
export const STATUS_CHANGE_PACKAGES_ERROR = "STATUS_CHANGE_PACKAGES_ERROR";

export const SET_PACKAGE = "SET_PACKAGE"
export const CLEAR_PACKAGE_STATE = "CLEAR_PACKAGE_STATE";

export const SET_PACKAGES_PAGINATION = "SET_PACKAGES_PAGINATION";
