import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    GET_USER_PURCHASES,
    GET_USER_PURCHASES_SUCCESS,
    GET_USER_PURCHASES_ERROR,
    SET_USER_PURCHASES_PAGINATION,
    GET_USER_PACKAGES,
    GET_USER_PACKAGES_SUCCESS,
    GET_USER_PACKAGES_ERROR,
    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAIL,
    CHANGE_PROFILE_PASSWORD,
    CHANGE_PROFILE_PASSWORD_SUCCESS,
    CHANGE_PROFILE_PASSWORD_FAIL,

    CLEAR_USER_PROFILE_STATE,
} from "./actionTypes";

const initialState = {
    purchases: [],
    packages: [],
    profile: {},
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loading: false,
    formLoading: false,
    purchaseLoading: false,
    success: '',
    error: '',
};

const Profile = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PROFILE:
            return {
                ...state,
                loading: true
            };
        case GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                loading: false
            }
        case GET_USER_PROFILE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_USER_PURCHASES:
            return {
                ...state,
                purchaseLoading: true
            };
        case GET_USER_PURCHASES_SUCCESS:
            return {
                ...state,
                purchases: action.payload,
                purchaseLoading: false
            }
        case GET_USER_PURCHASES_ERROR:
            return {
                ...state,
                error: action.payload,
                purchaseLoading: false
            };
        case SET_USER_PURCHASES_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                }
            };
        case GET_USER_PACKAGES:
            return {
                ...state,
                formLoading: true
            };
        case GET_USER_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: action.payload,
                formLoading: false
            }
        case GET_USER_PACKAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };
        case EDIT_PROFILE:
            return {
                ...state,
                loading: true
            };
        case EDIT_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.payload,
                success: 'Profile was successfully edited',
                loading: false
            };
        case EDIT_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case CHANGE_PROFILE_PASSWORD:
            return {
                ...state,
                formLoading: true
            };
        case CHANGE_PROFILE_PASSWORD_SUCCESS:
            return {
                ...state,
                success: 'Password was successfully changed',
                formLoading: false
            };
        case CHANGE_PROFILE_PASSWORD_FAIL:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };

        case CLEAR_USER_PROFILE_STATE:
            return {
                ...state,
                error: '',
                success: '',
                loading: false,
                formLoading: false
            };
        default:
            return state;
    }
}

export default Profile;
