import {
    GET_AI_SEARCH_LOGS,
    GET_AI_SEARCH_LOGS_SUCCESS,
    GET_AI_SEARCH_LOGS_ERROR,
    GET_AI_VIEWED_LOGS,
    GET_AI_VIEWED_LOGS_SUCCESS,
    GET_AI_VIEWED_LOGS_ERROR,
    EXPORT_AI_SEARCH_LOGS,
    EXPORT_AI_SEARCH_LOGS_SUCCESS,
    EXPORT_AI_SEARCH_LOGS_ERROR,
    EXPORT_AI_VIEWED_LOGS,
    EXPORT_AI_VIEWED_LOGS_SUCCESS,
    EXPORT_AI_VIEWED_LOGS_ERROR,
    SET_AI_LOGS_SEARCH_PAGINATION,
    SET_AI_LOGS_VIEWED_PAGINATION,
    CLEAR_AI_LOGS_STATE
} from "./actionTypes";


export const getAiSearchLogs = (filters) => ({
    type: GET_AI_SEARCH_LOGS,
    payload: {filters}
})
export const getAiSearchLogsSuccess = (logs) => ({
    type: GET_AI_SEARCH_LOGS_SUCCESS,
    payload: logs
})
export const getAiSearchLogsError = (error) => ({
    type: GET_AI_SEARCH_LOGS_ERROR,
    payload: error
})

export const getAiViewedLogs = (filters) => ({
    type: GET_AI_VIEWED_LOGS,
    payload: {filters}
})
export const getAiViewedLogsSuccess = (logs) => ({
    type: GET_AI_VIEWED_LOGS_SUCCESS,
    payload: logs
})
export const getAiViewedLogsError = (error) => ({
    type: GET_AI_VIEWED_LOGS_ERROR,
    payload: error
})

export const exportAiSearchedLogs = (dates) => ({
    type: EXPORT_AI_SEARCH_LOGS,
    payload: { dates }
})

export const exportAiSearchedLogsSuccess = () => ({
    type: EXPORT_AI_SEARCH_LOGS_SUCCESS,
});

export const exportAiSearchedLogsError = error => ({
    type: EXPORT_AI_SEARCH_LOGS_ERROR,
    payload: error,
});

export const exportAiViewedLogs = (dates) => ({
    type: EXPORT_AI_VIEWED_LOGS,
    payload: { dates }
})

export const exportAiViewedLogsSuccess = () => ({
    type: EXPORT_AI_VIEWED_LOGS_SUCCESS,
});

export const exportAiViewedLogsError = error => ({
    type: EXPORT_AI_VIEWED_LOGS_ERROR,
    payload: error,
});

export const setAiLogsSearchedPagination = (pagination) => ({
    type: SET_AI_LOGS_SEARCH_PAGINATION,
    payload: pagination
})

export const setAiLogsViewedPagination = (pagination) => ({
    type: SET_AI_LOGS_VIEWED_PAGINATION,
    payload: pagination
})

export const clearAiLogsState = () => ({
    type: CLEAR_AI_LOGS_STATE,
});
