export const IMPORT_ACTIVE_INGREDIENTS = "IMPORT_ACTIVE_INGREDIENTS";
export const IMPORT_ACTIVE_INGREDIENTS_SUCCESS = "IMPORT_ACTIVE_INGREDIENTS_SUCCESS";
export const IMPORT_ACTIVE_INGREDIENTS_ERROR = "IMPORT_ACTIVE_INGREDIENTS_ERROR";


export const IMPORT_INTERMEDIATE = "IMPORT_INTERMEDIATE";
export const IMPORT_INTERMEDIATE_SUCCESS = "IMPORT_INTERMEDIATE_SUCCESS";
export const IMPORT_INTERMEDIATE_ERROR = "IMPORT_INTERMEDIATE_ERROR";


export const GET_IMPORT_SETTINGS = "GET_IMPORT_SETTINGS";
export const GET_IMPORT_SETTINGS_SUCCESS = "GET_IMPORT_SETTINGS_SUCCESS";
export const GET_IMPORT_SETTINGS_ERROR = "GET_IMPORT_SETTINGS_ERROR";

export const SETUP_AI_MAX_NUMBER = "SETUP_AI_MAX_NUMBER";
export const SETUP_AI_MAX_NUMBER_SUCCESS = "SETUP_AI_MAX_NUMBER_SUCCESS";
export const SETUP_AI_MAX_NUMBER_ERROR = "SETUP_AI_MAX_NUMBER_ERROR";


export const CLEAR_SETTINGS_STATE = "CLEAR_SETTINGS_STATE"

