// sagas.js
import { call, put, takeLatest } from 'redux-saga/effects';
import { loadStripe } from '@stripe/stripe-js';
import { START_STRIPE_CHECKOUT } from './actionTypes';
import { stripeCheckoutSuccess, stripeCheckoutFailure } from './actions';

import {post} from "../../../library/http/apiHelpers";
import * as url from "../../../library/http/urlHelpers";

async function initiateStripeCheckout(options) {
    const stripePromise = loadStripe('pk_live_51Ocm4zHdZj718Nf03umaHteeB2c1zrdwL0jRdwMmKVxbnJJ7KivIVFLW1gAcS6P941XmJ0PCPfDBg6izrdFS581900ZYMzl9Tq');

    const stripe = await stripePromise;
    const response = await post(url.STRIPE_CHECKOUT, options);
    const session = response;
    // Redirect to Checkout
    const result = await stripe.redirectToCheckout({
        sessionId: session.id,
    });

    if (result.error) {
        throw new Error(result.error.message);
    }
    return session; // Assuming the session contains information you might need
}

function* handleStripeCheckout({payload: {option}}) {
    try {
        const result = yield call(initiateStripeCheckout, option); // Assume this function initiates Stripe Checkout
        yield put(stripeCheckoutSuccess(result));
    } catch (error) {
        console.log(error.toString())
        yield put(stripeCheckoutFailure(error.toString()));
    }
}

export default function* stripeSaga() {
    yield takeLatest(START_STRIPE_CHECKOUT, handleStripeCheckout);
}