import React from "react";
import {Alert, Form, Input, InputNumber, Select, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {clearUserState, userAdditionalSearches} from "../../../redux/admin/users/actions";
import {validateFromServer} from "../../../library/helpers/errorMessageParse";

const UserAdditionalSearchesForm = ({user_id}) => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { formLoading, error } = useSelector(state => state.Users);
    const handleUserAdditionalSearch = (formData) => {
        const data = {
            user_id: user_id,
            searches: formData.searches,
        }
        dispatch(userAdditionalSearches(data))
    }

    if (error) {
        if (error.status === 422) {
            form.setFields(validateFromServer(error));
        }
        dispatch(clearUserState());
    }

    return (
        <Spin spinning={formLoading}>
            <Form form={form}
                  name="user_additional_search_form"
                  id="user_additional_search_form"
                  layout={'vertical'}
                  onFinish={handleUserAdditionalSearch}
            >
                <Form.Item
                    className="isoInputWrapper"
                    label="Input number of searches"
                    name="searches"
                    rules={[{
                        required: true,
                        message: 'Please input number of searches'
                    }]}>
                    <InputNumber placeholder={'Number of Searches'} style={{width: "100%"}}/>
                </Form.Item>
            </Form>
        </Spin>
    )
}

export default UserAdditionalSearchesForm