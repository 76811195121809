import {
    GET_ACTIVE_INGREDIENTS,
    GET_ACTIVE_INGREDIENTS_SUCCESS,
    GET_ACTIVE_INGREDIENTS_ERROR,
    EXPORT_ACTIVE_INGREDIENTS,
    EXPORT_ACTIVE_INGREDIENTS_SUCCESS,
    EXPORT_ACTIVE_INGREDIENTS_ERROR,
    SET_ACTIVE_INGREDIENTS_QUERY,
    SET_ACTIVE_INGREDIENTS_PAGINATION,
    CLEAR_ACTIVE_INGREDIENTS_STATE
} from "./actionTypes";

export const getActiveIngredients = (filters) => ({
    type: GET_ACTIVE_INGREDIENTS,
    payload: {filters}
})
export const getActiveIngredientsSuccess = (active_ingredients) => ({
    type: GET_ACTIVE_INGREDIENTS_SUCCESS,
    payload: active_ingredients
})
export const getActiveIngredientsError = (error) => ({
    type: GET_ACTIVE_INGREDIENTS_ERROR,
    payload: error
})

export const exportActiveIngredients = (id, name) => ({
    type: EXPORT_ACTIVE_INGREDIENTS,
    payload: {id, name}
})
export const exportActiveIngredientsSuccess = () => ({
    type: EXPORT_ACTIVE_INGREDIENTS_SUCCESS,
})
export const exportActiveIngredientsError = (error) => ({
    type: EXPORT_ACTIVE_INGREDIENTS_ERROR,
    payload: error
})

export const setActiveIngredientSearchQuery = (query) => ({
    type: SET_ACTIVE_INGREDIENTS_QUERY,
    payload: query,
});

export const setActiveIngredientPagination = (pagination) => ({
    type: SET_ACTIVE_INGREDIENTS_PAGINATION,
    payload: pagination
})

export const clearActiveIngredientState = () => ({
    type: CLEAR_ACTIVE_INGREDIENTS_STATE,
})
