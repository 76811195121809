import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import menuSaga from './menu/saga'
import routesSaga from './routes/saga'
import profileSaga from "./profile/saga";
import usersSaga from "./admin/users/saga";
import purchaseSaga from "./admin/purchases/saga";
import packagesSaga from "./admin/packages/saga";
import settingsSaga from "./admin/Settings/saga";
import aiLogsSaga from "./admin/activeIngredientsLogs/saga";
import interLogsSaga from "./admin/intermediatesLogs/saga";

import activeIngredientsSaga from "./user/activeIngredients/saga";
import intermediatesSaga from "./user/intermediates/saga";
import stripeSaga from "./user/stripe/saga";


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    menuSaga(),
    routesSaga(),
    profileSaga(),
    purchaseSaga(),
    packagesSaga(),
    usersSaga(),
    settingsSaga(),
    aiLogsSaga(),
    interLogsSaga(),

    activeIngredientsSaga(),
    intermediatesSaga(),
    stripeSaga()
  ]);
}
