import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    VERIFY_USER,
    VERIFY_USER_SUCCESS,
    VERIFY_USER_ERROR,
    SENT_VERIFY_EMAIL,
    SENT_VERIFY_EMAIL_SUCCESS,
    SENT_VERIFY_EMAIL_ERROR,
    CHANGE_USER_PASSWORD,
    CHANGE_USER_PASSWORD_SUCCESS,
    CHANGE_USER_PASSWORD_ERROR,
    EXPORT_USERS,
    EXPORT_USERS_SUCCESS,
    EXPORT_USERS_ERROR,
    ADD_USER_ADDITIONAL_SEARCHES,
    ADD_USER_ADDITIONAL_SEARCHES_SUCCESS,
    ADD_USER_ADDITIONAL_SEARCHES_ERROR,
    SET_USER_PAGINATION,
    SET_USER,
    CLEAR_USER_STATE
} from "./actionTypes";

const initialState = {
    users: [],
    user: {},
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
    loading: false,
    formLoading: false,
    exportLoading: false,
    success: '',
    error: '',
};

const Users = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                loading: true
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false
            };
        case GET_USERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case CREATE_USER:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                success: 'User was successfully created',
                users: [...state.users, action.payload],
                formLoading: false
            };
        case CREATE_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_USER:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                success: "User was successfully edited",
                formLoading: false,
            };
        case UPDATE_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case DELETE_USER:
            return {
                ...state,
                loading: true
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload),
                success: "User was successfully deleted",
                loading: false
            };
        case DELETE_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case VERIFY_USER:
            return {
                ...state,
                loading: true
            };
        case VERIFY_USER_SUCCESS:
            return {
                ...state,
                success: "User was successfully verified",
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                loading: false
            };
        case VERIFY_USER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case SENT_VERIFY_EMAIL:
            return {
                ...state,
                loading: true
            };
        case SENT_VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                success: "Verification email was sent successfully",
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                loading: false
            };
        case SENT_VERIFY_EMAIL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };

        case CHANGE_USER_PASSWORD:
            return {
                ...state,
                formLoading: true,
            };
        case CHANGE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                success: "User password was changed successfully",
                formLoading: false,
            };
        case CHANGE_USER_PASSWORD_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };

        case EXPORT_USERS:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_USERS_SUCCESS:
            return {
                ...state,
                success: "File was successfully exported",
                exportLoading: false
            };
        case EXPORT_USERS_ERROR:
            return {
                ...state,
                exportLoading: false,
                error: action.payload,
            };

        case ADD_USER_ADDITIONAL_SEARCHES:
            return {
                ...state,
                formLoading: true,
            };
        case ADD_USER_ADDITIONAL_SEARCHES_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
                success: "Additional searches were successfully added",
                formLoading: false,
            };
        case ADD_USER_ADDITIONAL_SEARCHES_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };

        case SET_USER_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                }
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case CLEAR_USER_STATE:
            return {
                ...state,
                loading: false,
                formLoading: false,
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default Users;
