import React, {useEffect} from "react";
import {Form, Button, Input, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {validateFromServer} from "../../../library/helpers/errorMessageParse";
import {changeUserPassword, clearUserState} from "../../../redux/admin/users/actions";

const ChangePasswordForm = ({user_id}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {formLoading, error} = useSelector(state => state.Users)
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);

    useEffect(() => {
        form.resetFields()
    }, [modalVisibility]);

    const validatePassword = (rule, value, callback) => {
        let password = form.getFieldsValue().password;
        if (value && value !== password) {
            callback("Passwords don't match");
        } else {
            callback();
        }
    };

    const resetPasswordFunction = (data) => {
        dispatch(changeUserPassword(data, user_id))
    }

    if (error) {
        if (error.status === 422) {
           form.setFields(validateFromServer(error));
        }
        dispatch(clearUserState());
    }

    return (
        <Spin spinning={formLoading}>
            <Form form={form}
                  name="change_user_password_form"
                  id="change_user_password_form"
                  onFinish={resetPasswordFunction}
            >
                <Form.Item
                    className="isoInputWrapper"
                    name="password"
                    rules={[{
                        required: true,
                        message: 'Please enter user password'
                    }]}>
                    <Input.Password size="large" type="password" placeholder="New Password" name="password"/>
                </Form.Item>
                <Form.Item
                    className="isoInputWrapper"
                    name="password_confirmation"
                    rules={[{
                        required: true,
                        message: 'Please enter user password confirmation'
                    },
                        {
                            validator: validatePassword
                        }
                    ]}>
                    <Input.Password size="large" type="password" placeholder="Confirm Password"
                                    name="password_confirmation"
                    />
                </Form.Item>
                <div style={{fontSize: '13px'}}><span>Password must:</span><br/>
                    - Be a minimum of 10 characters <br/>
                    - Include at least one lowercase letter (a-z) <br/>
                    - Include at least one uppercase letter (A-Z) <br/>
                    - Include at least one number (0-9) <br/>
                    - Include at least one special character
                </div>
            </Form>
        </Spin>
    );
}

export default ChangePasswordForm
