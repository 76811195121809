import React, {useEffect} from "react";
import {Col, DatePicker, Form, Input, Row, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {exportUsers} from "../../../redux/admin/users/actions";


const ExportUserModal = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const {exportLoading, error} = useSelector(state => state.Users)
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);

    useEffect(() => {
        form.resetFields()
    }, [modalVisibility]);

    const handleUserExport = (data) => {
       const formData = {
           start_date: moment(data.start_date).format('Y-MM-DD'),
           end_date: (data.end_date) ? moment(data.end_date).format('Y-MM-DD') : moment().format('Y-MM-DD')
       }
       dispatch(exportUsers(formData))
    }

    return (
        <Spin spinning={exportLoading}>
            <Form form={form}
                  layout={'vertical'}
                  name="export_user_form"
                  id="export_user_form"
                  onFinish={handleUserExport}
            >
                <Row gutter={18}>
                    <Col span={12}>
                    <Form.Item
                        className="isoInputWrapper"
                        name="start_date"
                        label={'Select Start Date'}
                        rules={[{
                            required: true,
                            message: 'Please enter start date'
                        }]}>
                        <DatePicker style={{width: "100%"}} name={'start_date'}/>
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        className="isoInputWrapper"
                        label={'Select End Date'}
                        name="end_date"
                    >
                        <DatePicker style={{width: "100%"}} name={'end_date'}/>
                    </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    )
}

export default ExportUserModal
