import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import {
    GET_PACKAGES,
    CREATE_PACKAGES,
    UPDATE_PACKAGES,
    DELETE_PACKAGES,
    GET_PACKAGES_SUCCESS,
    STATUS_CHANGE_PACKAGES
} from "./actionTypes";
import {
    getPackagesSuccess,
    getPackagesError,
    getPackageStatusesSuccess,
    getPackageStatusesError,
    setPackagesPagination,
    createPackageSuccess,
    createPackageError,
    updatePackageSuccess,
    updatePackageError,
    deletePackageSuccess,
    deletePackageError, changeStatusPackageSuccess, changeStatusPackageError
} from "./actions";

import {
    createPackagesRequest,
    updatePackagesRequest,
    deletePackagesRequest,
    getPackagesRequest,
    getPackagesStatusesRequest,
    statusChangePackagesRequest
} from '../../../library/http/backendHelpers';

function* getPackagesSaga({payload: {filters}}){
    try {
        const response = yield call(getPackagesRequest, filters);
        yield put(setPackagesPagination(response.meta))
        yield put(getPackagesSuccess(response.data))
      } catch (error) {
        yield put(getPackagesError(error));
      }
}

function* getPackagesStatusesSaga(){
    try {
        const response = yield call(getPackagesStatusesRequest);
        yield put(getPackageStatusesSuccess(response.data))
    } catch (error) {
        yield put(getPackageStatusesError(error));
    }
}

function* createPackage({payload: {packages}}) {
    try {
        const response = yield call(createPackagesRequest, packages);
        yield put(createPackageSuccess(response.data));
    } catch (error) {
        yield put(createPackageError(error))
    }
}

function* updatePackage({payload: {packages, package_id}}) {
    try {
        const response = yield call(updatePackagesRequest, packages, package_id);
        yield put(updatePackageSuccess(response.data));
    } catch (error) {
        yield put(updatePackageError(error))
    }
}

function* changeStatusPackageSaga({payload: {package_id, status}}) {
    try {
        const response = yield call(statusChangePackagesRequest, package_id, status);
        yield put(changeStatusPackageSuccess(response.data));
    } catch (error) {
        yield put(changeStatusPackageError(error))
    }
}

function* deletePackage({payload: {package_id}}) {
    try {
        const response = yield call(deletePackagesRequest, package_id);
        yield put(deletePackageSuccess(package_id));
    } catch (error) {
        yield put(deletePackageError(error))
    }
}


function* packagesSaga() {
    yield takeEvery(GET_PACKAGES, getPackagesSaga);
    yield takeEvery(GET_PACKAGES_SUCCESS, getPackagesStatusesSaga);
    yield takeEvery(CREATE_PACKAGES, createPackage);
    yield takeEvery(UPDATE_PACKAGES, updatePackage);
    yield takeEvery(DELETE_PACKAGES, deletePackage);
    yield takeEvery(STATUS_CHANGE_PACKAGES, changeStatusPackageSaga);
  }

export default packagesSaga
