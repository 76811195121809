export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const SET_USER_PAGINATION = "SET_USER_PAGINATION";

export const SET_USER = "SET_USER";

export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"


export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS"
export const VERIFY_USER_ERROR = "VERIFY_USER_ERROR"

export const SENT_VERIFY_EMAIL = "SENT_VERIFY_EMAIL";
export const SENT_VERIFY_EMAIL_SUCCESS = "SENT_VERIFY_EMAIL_SUCCESS"
export const SENT_VERIFY_EMAIL_ERROR = "SENT_VERIFY_EMAIL_ERROR"

export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS"
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR"

export const EXPORT_USERS = "EXPORT_USERS";
export const EXPORT_USERS_SUCCESS = "EXPORT_USERS_SUCCESS"
export const EXPORT_USERS_ERROR = "EXPORT_USERS_ERROR"


export const ADD_USER_ADDITIONAL_SEARCHES = "ADD_USER_ADDITIONAL_SEARCHES";
export const ADD_USER_ADDITIONAL_SEARCHES_SUCCESS = "ADD_USER_ADDITIONAL_SEARCHES_SUCCESS";
export const ADD_USER_ADDITIONAL_SEARCHES_ERROR = "ADD_USER_ADDITIONAL_SEARCHES_ERROR";



export const CLEAR_USER_STATE = "CLEAR_USER_STATE";
