import {
    IMPORT_ACTIVE_INGREDIENTS,
    IMPORT_ACTIVE_INGREDIENTS_SUCCESS,
    IMPORT_ACTIVE_INGREDIENTS_ERROR,
    IMPORT_INTERMEDIATE,
    IMPORT_INTERMEDIATE_SUCCESS,
    IMPORT_INTERMEDIATE_ERROR,
    GET_IMPORT_SETTINGS,
    GET_IMPORT_SETTINGS_SUCCESS,
    GET_IMPORT_SETTINGS_ERROR,
    SETUP_AI_MAX_NUMBER,
    SETUP_AI_MAX_NUMBER_SUCCESS,
    SETUP_AI_MAX_NUMBER_ERROR,
    CLEAR_SETTINGS_STATE
} from "./actionTypes";

const initState = {
    import_settings: {
        ai_import: {},
        intermediate_import: {},
        ingredients_max: 0,
        ready_to_import: 0
    },
    success: '',
    error: '',
    loading: false,
    formLoading: false
};

const Settings = (state = initState, action) => {
    switch (action.type) {
        case IMPORT_ACTIVE_INGREDIENTS:
            return {
                ...state,
                loading: true
            };
        case IMPORT_ACTIVE_INGREDIENTS_SUCCESS:
            return {
                ...state,
                success: "Active Ingredients import finished successfully",
                loading: false
            };
        case IMPORT_ACTIVE_INGREDIENTS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case IMPORT_INTERMEDIATE:
            return {
                ...state,
                loading: true
            };
        case IMPORT_INTERMEDIATE_SUCCESS:
            return {
                ...state,
                success: "Intermediate import finished successfully",
                loading: false
            };
        case IMPORT_INTERMEDIATE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_IMPORT_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case GET_IMPORT_SETTINGS_SUCCESS:
            return {
                ...state,
                import_settings: {
                    ai_import: action.payload.ai_import,
                    intermediate_import: action.payload.intermediate_import,
                    ingredients_max: action.payload.ingredients_max,
                    ready_to_import: action.payload.ready_to_import
                },
                loading: false
            };
        case GET_IMPORT_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case SETUP_AI_MAX_NUMBER:
            return {
                ...state,
                formLoading: true
            };
        case SETUP_AI_MAX_NUMBER_SUCCESS:
            return {
                ...state,
                success: 'Max No. of active ingredients was successfully updated',
                import_settings: {
                    ...state.import_settings,
                    ingredients_max: action.payload
                },
                formLoading: false
            };
        case SETUP_AI_MAX_NUMBER_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case CLEAR_SETTINGS_STATE:
            return {
                ...state,
                loading: false,
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default Settings;
