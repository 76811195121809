import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import {EXPORT_INTER_SEARCH_LOGS, EXPORT_INTER_VIEWED_LOGS, GET_INTER_SEARCH_LOGS, GET_INTER_VIEWED_LOGS} from "./actionTypes";
import {
    getInterSearchLogsSuccess,
    getInterSearchLogsError,
    getInterViewedLogsSuccess,
    getInterViewedLogsError,
    exportInterSearchedLogsSuccess,
    exportInterSearchedLogsError,
    exportInterViewedLogsSuccess,
    exportInterViewedLogsError,
    setInterLogsSearchedPagination,
    setInterLogsViewedPagination
} from "./actions";

import {
    getInterSearchedLogsRequest,
    getInterViewedLogsRequest,
    exportInterSearchedLogsRequest,
    exportInterViewedLogsRequest
} from '../../../library/http/backendHelpers';
import moment from "moment";

function* getInterSearchedLogsSaga({payload: {filters}}){
    try {
        const response = yield call(getInterSearchedLogsRequest, filters);
        yield put(setInterLogsSearchedPagination(response.meta))
        yield put(getInterSearchLogsSuccess(response.data))
      } catch (error) {
        yield put(getInterSearchLogsError(error));
      }
}

function* getInterViewedLogsSaga({payload: {filters}}){
    try {
        const response = yield call(getInterViewedLogsRequest, filters);
        yield put(setInterLogsViewedPagination(response.meta))
        yield put(getInterViewedLogsSuccess(response.data))
    } catch (error) {
        yield put(getInterViewedLogsError(error));
    }
}

function* exportInterSearchedSaga({ payload: { dates }}) {
    try {
        const filename  = 'intermediate-searched-logs-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportInterSearchedLogsRequest, dates, filename);
        yield put(exportInterSearchedLogsSuccess());
    }catch (error) {
        yield put(exportInterSearchedLogsError(error))
    }
}

function* exportInterViewedSaga({ payload: { dates }}) {
    try {
        const filename  = 'intermediate-viewed-logs-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportInterViewedLogsRequest, dates, filename);
        yield put(exportInterViewedLogsSuccess());
    }catch (error) {
        yield put(exportInterViewedLogsError(error))
    }
}


function* interLogsSaga() {
    yield takeEvery(GET_INTER_SEARCH_LOGS, getInterSearchedLogsSaga);
    yield takeEvery(GET_INTER_VIEWED_LOGS, getInterViewedLogsSaga);
    yield takeEvery(EXPORT_INTER_SEARCH_LOGS, exportInterSearchedSaga);
    yield takeEvery(EXPORT_INTER_VIEWED_LOGS, exportInterViewedSaga);
}

export default interLogsSaga
