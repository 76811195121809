import { call, all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import {EXPORT_PURCHASES, GET_PURCHASES} from "./actionTypes";
import {
    getPurchasesSuccess,
    getPurchasesError,
    setPurchasePagination,
    exportPurchasesSuccess,
    exportPurchasesError
} from "./actions";

import {exportPurchasesRequest, getPurchasesRequest} from '../../../library/http/backendHelpers';
import moment from "moment";

const history = createBrowserHistory();


function* getPurchasesSaga({payload: {filters}}){
    try {
        const response = yield call(getPurchasesRequest, filters);
        yield put(setPurchasePagination(response.meta))
        yield put(getPurchasesSuccess(response.data))
      } catch (error) {
        yield put(getPurchasesError(error));
      }
}

function* exportPurchasesSaga({ payload: { dates }}) {
    try {
        const filename  = 'purchases-report-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportPurchasesRequest, dates, filename);
        yield put(exportPurchasesSuccess());
    }catch (error) {
        yield put(exportPurchasesError(error))
    }
}


function* purchaseSaga() {
    yield takeEvery(GET_PURCHASES, getPurchasesSaga);
    yield takeEvery(EXPORT_PURCHASES, exportPurchasesSaga);
  }

export default purchaseSaga
