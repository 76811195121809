import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    GET_USER_PURCHASES,
    GET_USER_PURCHASES_SUCCESS,
    GET_USER_PURCHASES_ERROR,
    SET_USER_PURCHASES_PAGINATION,
    GET_USER_PACKAGES,
    GET_USER_PACKAGES_SUCCESS,
    GET_USER_PACKAGES_ERROR,
    EDIT_PROFILE,
    EDIT_PROFILE_SUCCESS,
    EDIT_PROFILE_FAIL,
    CHANGE_PROFILE_PASSWORD,
    CHANGE_PROFILE_PASSWORD_SUCCESS,
    CHANGE_PROFILE_PASSWORD_FAIL,
    CLEAR_USER_PROFILE_STATE,
} from "./actionTypes";

export const getUserProfile = () => ({
    type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = (data) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: data
});

export const getUserProfileError = (error) => ({
    type: GET_USER_PROFILE_ERROR,
    payload: error
});

export const getUserPurchases = (filters) => ({
    type: GET_USER_PURCHASES,
    payload: {filters}
});

export const getUserPurchasesSuccess = (data) => ({
    type: GET_USER_PURCHASES_SUCCESS,
    payload: data
});

export const getUserPurchasesError = (error) => ({
    type: GET_USER_PURCHASES_ERROR,
    payload: error
});

export const setUserPurchasesPagination = pagination => ({
    type: SET_USER_PURCHASES_PAGINATION,
    payload: pagination
});

export const getUserPackages = () => ({
    type: GET_USER_PACKAGES,
});

export const getUserPackagesSuccess = (data) => ({
    type: GET_USER_PACKAGES_SUCCESS,
    payload: data
});

export const getUserPackagesError = (error) => ({
    type: GET_USER_PACKAGES_ERROR,
    payload: error
});

export const updateUserProfile = (user) => ({
    type: EDIT_PROFILE,
    payload: {user}
});
export const updateUserProfileSuccess = user => ({
    type: EDIT_PROFILE_SUCCESS,
    payload: user,
});
export const updateUserProfileFail = error => ({
    type: EDIT_PROFILE_FAIL,
    payload: error,
});

export const changeProfilePassword = (password) => ({
    type: CHANGE_PROFILE_PASSWORD,
    payload: {password}
});
export const changeProfilePasswordSuccess = () => ({
    type: CHANGE_PROFILE_PASSWORD_SUCCESS,
});
export const changeProfilePasswordFail = error => ({
    type: CHANGE_PROFILE_PASSWORD_FAIL,
    payload: error,
});

export const clearProfileState = (state) => ({
    type: CLEAR_USER_PROFILE_STATE,
    payload: state
});
