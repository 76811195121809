import {
    GET_INTERMEDIATES,
    GET_INTERMEDIATES_SUCCESS,
    GET_INTERMEDIATES_ERROR,
    EXPORT_INTERMEDIATES,
    EXPORT_INTERMEDIATES_SUCCESS,
    EXPORT_INTERMEDIATES_ERROR,
    SET_INTERMEDIATES_QUERY,
    SET_INTERMEDIATES_PAGINATION,
    CLEAR_INTERMEDIATES_STATE
} from "./actionTypes";


export const getIntermediates = (filters) => ({
    type: GET_INTERMEDIATES,
    payload: {filters}
})
export const getIntermediatesSuccess = (active_ingredients) => ({
    type: GET_INTERMEDIATES_SUCCESS,
    payload: active_ingredients
})
export const getIntermediatesError = (error) => ({
    type: GET_INTERMEDIATES_ERROR,
    payload: error
})

export const exportIntermediate = (id, name) => ({
    type: EXPORT_INTERMEDIATES,
    payload: {id, name}
})
export const exportIntermediateSuccess = () => ({
    type: EXPORT_INTERMEDIATES_SUCCESS,
})
export const exportIntermediateError = (error) => ({
    type: EXPORT_INTERMEDIATES_ERROR,
    payload: error
})

export const setIntermediateSearchQuery = (query) => ({
    type: SET_INTERMEDIATES_QUERY,
    payload: query,
});

export const setIntermediatePagination = (pagination) => ({
    type: SET_INTERMEDIATES_PAGINATION,
    payload: pagination
})

export const clearIntermediateState = () => ({
    type: CLEAR_INTERMEDIATES_STATE,
})
