import {
    GET_PACKAGES,
    GET_PACKAGES_SUCCESS,
    GET_PACKAGES_ERROR,
    GET_PACKAGES_STATUSES,
    GET_PACKAGES_STATUSES_SUCCESS,
    GET_PACKAGES_STATUSES_ERROR,
    CREATE_PACKAGES,
    CREATE_PACKAGES_SUCCESS,
    CREATE_PACKAGES_ERROR,
    UPDATE_PACKAGES,
    UPDATE_PACKAGES_SUCCESS,
    UPDATE_PACKAGES_ERROR,
    DELETE_PACKAGES,
    DELETE_PACKAGES_SUCCESS,
    DELETE_PACKAGES_ERROR,
    STATUS_CHANGE_PACKAGES,
    STATUS_CHANGE_PACKAGES_SUCCESS,
    STATUS_CHANGE_PACKAGES_ERROR,
    SET_PACKAGE,
    CLEAR_PACKAGE_STATE,
    SET_PACKAGES_PAGINATION
} from "./actionTypes";

const initState = {
    packages: [],
    package: {},
    packages_statuses: [],
    success: '',
    error: '',
    loading: false,
    formLoading: false,
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    },
};

const Packages = (state = initState, action) => {
    switch (action.type) {
        case GET_PACKAGES:
            return {
                ...state,
                loading: true
            };
        case GET_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: action.payload,
                loading: false
            };
        case GET_PACKAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };

        case GET_PACKAGES_STATUSES:
            return {
                ...state,
                formLoading: true
            };
        case GET_PACKAGES_STATUSES_SUCCESS:
            return {
                ...state,
                packages_statuses: action.payload,
                formLoading: false
            };
        case GET_PACKAGES_STATUSES_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false
            };

        case SET_PACKAGES_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };

        case CREATE_PACKAGES:
            return {
                ...state,
                formLoading: true
            };
        case CREATE_PACKAGES_SUCCESS:
            return {
                ...state,
                success: 'Package was successfully created',
                packages: [ action.payload, ...state.packages],
                formLoading: false
            };
        case CREATE_PACKAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case UPDATE_PACKAGES:
            return {
                ...state,
                formLoading: true,
            };
        case UPDATE_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: state.packages.map((item) => item.id === action.payload.id ? action.payload : item),
                success: "Package was successfully edited",
                formLoading: false,
            };
        case UPDATE_PACKAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                formLoading: false,
            };
        case STATUS_CHANGE_PACKAGES:
            return {
                ...state,
                loading: true,
            };
        case STATUS_CHANGE_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: state.packages.map((item) => item.id === action.payload.id ? action.payload : item),
                success: "Package status was successfully changed",
                loading: false,
            };
        case STATUS_CHANGE_PACKAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DELETE_PACKAGES:
            return {
                ...state,
                loading: true
            };
        case DELETE_PACKAGES_SUCCESS:
            return {
                ...state,
                packages: state.packages.filter(item => item.id !== action.payload),
                success: "Package was successfully deleted",
                loading: false
            };
        case DELETE_PACKAGES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SET_PACKAGE:
            return {
                ...state,
                package: action.payload
            };
        case CLEAR_PACKAGE_STATE:
            return {
                ...state,
                loading: false,
                formLoading: false,
                success: '',
                error: '',
            };
        default:
            return state;
    }
}

export default Packages;
