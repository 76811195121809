import { call, all, takeEvery, put, fork } from 'redux-saga/effects';

import {EXPORT_AI_SEARCH_LOGS, EXPORT_AI_VIEWED_LOGS, GET_AI_SEARCH_LOGS, GET_AI_VIEWED_LOGS} from "./actionTypes";
import {
    getAiSearchLogsSuccess,
    getAiSearchLogsError,
    getAiViewedLogsSuccess,
    getAiViewedLogsError,
    exportAiSearchedLogsSuccess,
    exportAiSearchedLogsError,
    exportAiViewedLogsSuccess,
    exportAiViewedLogsError,
    setAiLogsSearchedPagination,
    setAiLogsViewedPagination
} from "./actions";

import {
    getAiSearchedLogsRequest,
    getAiViewedLogsRequest,
    exportAiSearchedLogsRequest,
    exportAiViewedLogsRequest
} from '../../../library/http/backendHelpers';
import moment from "moment";

function* getAiSearchedLogsSaga({payload: {filters}}){
    try {
        const response = yield call(getAiSearchedLogsRequest, filters);
        yield put(setAiLogsSearchedPagination(response.meta))
        yield put(getAiSearchLogsSuccess(response.data))
      } catch (error) {
        yield put(getAiSearchLogsError(error));
      }
}

function* getAiViewedLogsSaga({payload: {filters}}){
    try {
        const response = yield call(getAiViewedLogsRequest, filters);
        yield put(setAiLogsViewedPagination(response.meta))
        yield put(getAiViewedLogsSuccess(response.data))
    } catch (error) {
        yield put(getAiViewedLogsError(error));
    }
}

function* exportAiSearchedSaga({ payload: { dates }}) {
    try {
        const filename  = 'active-ingredients-searched-logs-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportAiSearchedLogsRequest, dates, filename);
        yield put(exportAiSearchedLogsSuccess());
    }catch (error) {
        yield put(exportAiSearchedLogsError(error))
    }
}

function* exportAiViewedSaga({ payload: { dates }}) {
    try {
        const filename  = 'active-ingredients-viewed-logs-'+ moment().format('Y-m-d HH:mm:ss') + '.xlsx';
        const response = yield call(exportAiViewedLogsRequest, dates, filename);
        yield put(exportAiViewedLogsSuccess());
    }catch (error) {
        yield put(exportAiViewedLogsError(error))
    }
}


function* aiLogsSaga() {
    yield takeEvery(GET_AI_SEARCH_LOGS, getAiSearchedLogsSaga);
    yield takeEvery(GET_AI_VIEWED_LOGS, getAiViewedLogsSaga);
    yield takeEvery(EXPORT_AI_SEARCH_LOGS, exportAiSearchedSaga);
    yield takeEvery(EXPORT_AI_VIEWED_LOGS, exportAiViewedSaga);
}

export default aiLogsSaga
