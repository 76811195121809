import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Divider, Form, Row, Space, Spin, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import ActiveIngredients from "../../../../redux/user/activeIngredients/reducer";
import {clearActiveIngredientState, exportActiveIngredients} from "../../../../redux/user/activeIngredients/actions";
import {closeModalMain, openModalMain} from "../../../../redux/rootModal/actions";


const ActiveIngredientViewModal = ({active_ingredient}) => {
    const dispatch = useDispatch()
    const {Text} = Typography;
    const [form] = Form.useForm()
    const {purchase_data} = useSelector(state => state.Auth)
    const {exportLoading, success} = useSelector(state => state.ActiveIngredients)
    const [showSuccessButton, setShowSuccessButton] = useState(false)
    const [continueButton, setContinueButton] = useState(true)
    const modalVisibility = useSelector((state) => state.rootModal.modalVisibility);

    const checkMarketStatus = (status) => {
        return (status === "Discontinued" || status === "Suspended" || status === "Withdrawn")
    }

    const message = (
        (continueButton && checkMarketStatus(active_ingredient.market_status)) ?
            <div>
                <Text strong>This molecule has market status: {active_ingredient.market_status}</Text>.<br/>{" "}
            </div> :
            <div>
                <Text strong>You have {purchase_data.searched_remaining} searches remaining</Text>.<br/>{" "}
                {purchase_data.searched_remaining === 0 ?
                    "Please purchase more searches to continue." :
                    ""}
            </div>
    );

    useEffect(() => {
        setShowSuccessButton(false)
        setContinueButton(true)
    }, [modalVisibility])

    const handleAdditionalSearchForm = () => {
        dispatch(closeModalMain());
        let props = {
            modalVisibility: true,
            modalType: "ADDITIONAL_SEARCH_MODAL",
            modalProps: {
                title: "Buy Additional Searches",
                width: 600,
                maskClosable: false,
                style: { top: "30px" },
                footer: [
                    <Button onClick={() => {dispatch(closeModalMain())}}>Cancel</Button>,
                    <Button form="additional_search_form" key="submit" htmlType="submit" type="primary">Proceed To Checkout</Button>
                ],
            },
        };
        dispatch(openModalMain(props));
    }

    const onAiExport = () => {
        dispatch(exportActiveIngredients(active_ingredient.id, active_ingredient.ai_name))
    }

    if (success !== '') {
        setShowSuccessButton(true)
        dispatch(clearActiveIngredientState())
    }

    const handleContinueButton = () => {
        setContinueButton(false)
    }



    return (
        <Spin spinning={exportLoading}>
        <Form
            id="ai_export_form"
            name="ai_export_form"
            form={form}
            onFinish={onAiExport}
        >
        <div style={{fontSize: "15px"}}>
            <Row style={{marginBottom: "5px"}}>
                <Col span={6}>
                    <Text strong>Name: </Text>
                </Col>
                <Col>
                    <Text>{active_ingredient.ai_name}</Text>
                </Col>
            </Row>
            <Row style={{marginBottom: "24px"}}>
                <Col span={6}>
                    <Text strong>Market Status: </Text>
                </Col>
                <Col>
                    <Text>{active_ingredient.market_status}</Text>
                </Col>
            </Row>
            <Row>
                <Alert
                    style={{width: "100%"}}
                    message={message}
                    description={`
                    ${
                        (continueButton && checkMarketStatus(active_ingredient.market_status)) ? 'Do you want to continue?' :
                        (purchase_data.searched_remaining === 0) ? '' : 'You are about to use one of your searches. Do you want to proceed?'
                    }`
                    }
                    type={`${(purchase_data.searched_remaining === 0 && continueButton === false) ? 'error': 'info'}`}
                />
            </Row>
            <Divider style={{margin: "18px 0"}}/>
            <Row>
                <Col span={12}>
                    <Button loading={exportLoading} onClick={() => dispatch(closeModalMain())}>Cancel</Button>
                </Col>
                <Col span={12} style={{display: "flex", justifyContent: "end"}}>
                    {
                        (continueButton && checkMarketStatus(active_ingredient.market_status)) ?
                            <Button type="primary" onClick={handleContinueButton}>Continue</Button>
                                :
                        (purchase_data.searched_remaining !== 0) ?
                        (showSuccessButton) ? <Button className={'success-button'}>PDF Downloaded Successfully</Button> :
                            <Button form="ai_export_form" key="submit" htmlType="submit" type="primary"
                                loading={exportLoading}>Proceed & Download PDF</Button> :
                        <Button type="primary" onClick={handleAdditionalSearchForm}>Buy additional searches</Button>
                    }
                </Col>
            </Row>
        </div>
        </Form>
        </Spin>
    )
}

export default ActiveIngredientViewModal;
