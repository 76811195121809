import {
    GET_INTERMEDIATES,
    GET_INTERMEDIATES_SUCCESS,
    GET_INTERMEDIATES_ERROR,
    EXPORT_INTERMEDIATES,
    EXPORT_INTERMEDIATES_SUCCESS,
    EXPORT_INTERMEDIATES_ERROR,
    SET_INTERMEDIATES_QUERY,
    SET_INTERMEDIATES_PAGINATION,
    CLEAR_INTERMEDIATES_STATE
} from "./actionTypes";


const initState = {
    intermediates: [],
    search_query: '',
    success: '',
    error: '',
    loading: false,
    exportLoading: false,
    pagination: {
        current: 1,
        pageSize: 0,
        total: 0
    }
};


const Intermediates = (state = initState, action) => {
    switch (action.type) {
        case GET_INTERMEDIATES:
            return {
                ...state,
                loading: true
            };
        case GET_INTERMEDIATES_SUCCESS:
            return {
                ...state,
                intermediates: action.payload,
                loading: false
            };

        case GET_INTERMEDIATES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case EXPORT_INTERMEDIATES:
            return {
                ...state,
                exportLoading: true
            };
        case EXPORT_INTERMEDIATES_SUCCESS:
            return {
                ...state,
                success: "Intermediate was successfully exported",
                exportLoading: false
            };

        case EXPORT_INTERMEDIATES_ERROR:
            return {
                ...state,
                error: action.payload,
                exportLoading: false
            };
        case SET_INTERMEDIATES_QUERY:
            return {
                ...state,
                search_query: action.payload
            };
        case SET_INTERMEDIATES_PAGINATION:
            return {
                ...state,
                pagination: {
                    current: action.payload.current_page,
                    pageSize: action.payload.per_page,
                    total: action.payload.total
                },
            };
        case CLEAR_INTERMEDIATES_STATE:
            return {
                ...state,
                success: '',
                error: '',
                loading: false,
                exportLoading: false
            }
        default:
            return state;
    }
}

export default Intermediates;
