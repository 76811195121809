import {
    GET_PURCHASES,
    GET_PURCHASES_SUCCESS,
    GET_PURCHASES_ERROR,
    EXPORT_PURCHASES,
    EXPORT_PURCHASES_SUCCESS,
    EXPORT_PURCHASES_ERROR,
    SET_PURCHASES_PAGINATION
} from "./actionTypes";

export const getPurchases = (filters) => ({
    type: GET_PURCHASES,
    payload: {filters}
})
export const getPurchasesSuccess = (purchases) => ({
    type: GET_PURCHASES_SUCCESS,
    payload: purchases
})
export const getPurchasesError = (error) => ({
    type: GET_PURCHASES_ERROR,
    payload: error
})

export const exportPurchases = (dates) => ({
    type: EXPORT_PURCHASES,
    payload: { dates }
})

export const exportPurchasesSuccess = () => ({
    type: EXPORT_PURCHASES_SUCCESS,
});

export const exportPurchasesError = error => ({
    type: EXPORT_PURCHASES_ERROR,
    payload: error,
});

export const setPurchasePagination = (pagination) => ({
    type: SET_PURCHASES_PAGINATION,
    payload: pagination
})
