import React from "react";
import {Card, Col, Row, Skeleton, Space, Typography} from "antd";
import {
    CreditCardOutlined,
    HomeOutlined,
    LaptopOutlined,
    MailOutlined,
    PhoneOutlined,
    UserOutlined
} from "@ant-design/icons";
import '../../Profile/Components/styles.css'

const UserProfileModal = ({user}) => {

    const { Text, Paragraph } = Typography;

    return (
        <Card
            bordered={false}
            cover={
                <Space direction="vertical">
                        <div className="isoImgWrapper"
                             style={{backgroundColor: '#1890ff'}}>
                            <div id="profileAvatar">
                              <span style={{
                                  fontSize: "32px",
                                  fontWeight: "bold",
                                  color: "white"
                              }}>{(Object.keys(user).length > 0) ? user.first_name?.charAt(0).toUpperCase() + user.last_name?.charAt(0).toUpperCase(): ''}</span>
                            </div>
                        </div>
                        <div style={{paddingTop: "20px"}}>
                            { (Object.keys(user).length > 0) ? <p className="userName">{ user?.first_name +' '+ user?.last_name }</p> : <Skeleton/> }
                        </div>
                        <div style={{color: "rgba(0,0,0,.85)", fontSize: "14px", textAlign: "center"}}>
                            <Text strong>Role</Text>: <Text>{user?.role}</Text>
                        </div>
                </Space>
            }
        >
            <div style={{fontSize: "15px"}}>
                <Row style={{marginBottom: "10px"}}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><UserOutlined/> <Text strong>First Name</Text>:
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><Text> {user?.first_name}</Text></Col>
                </Row>
                <Row style={{marginBottom: "10px"}}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><UserOutlined/> <Text strong>Last Name</Text>:
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><Text> {user?.last_name}</Text></Col>
                </Row>
                <Row style={{marginBottom: "10px"}}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><MailOutlined/> <Text strong>Email</Text>: </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><Text> {user?.email}</Text></Col>
                </Row>
                <Row style={{marginBottom: "10px"}}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><LaptopOutlined/> <Text strong>Job Title</Text>:
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>{user?.job_title ?
                        <Text>{user?.job_title}</Text> : <Text disabled>No Job Title</Text>}</Col>
                </Row>


                <Row style={{marginBottom: "10px"}}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><CreditCardOutlined/> <Text strong>Company</Text>:
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><Text> {user?.company}</Text></Col>
                </Row>


                <Row style={{marginBottom: "10px"}}>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><PhoneOutlined/> <Text strong>Phone</Text>: </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>{user?.phone_number ?
                        <Text>({user.country_code}) {user?.phone_number}</Text> :
                        <Text disabled>No Phone</Text>}</Col>
                </Row>


                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}><HomeOutlined/> <Text strong>Address</Text>:
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Text> {user?.primary_address}</Text>
                    </Col>
                </Row>

                {(user?.secondary_address) &&
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Text> {user?.secondary_address}</Text>
                    </Col>
                </Row>
                }
                {(user?.city || user?.state) &&
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Text> {user?.city}</Text>,
                        <Text> {user?.state}</Text>
                    </Col>
                </Row>
                }
                {(user?.postal_code) &&
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Text> {user?.postal_code}</Text>
                    </Col>
                </Row>
                }
                {(user?.country) &&
                <Row>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Text> {user?.country}</Text>
                    </Col>
                </Row>
                }
            </div>
        </Card>
    )
}

export default UserProfileModal
